import { Tag } from "~/common/components";
import {
  PATIENT_TERMINATED_STATUS,
  PatientConsultationStatus,
} from "~/common/constants";
import { replaceUnderscores } from "~/common/utils";
import { isConsultationCompleted } from "~/providers/utils";

interface PatientConsultationStatusTagProps {
  consultationStatus: PatientConsultationStatus;
  isTerminated?: boolean;
}

export const PatientConsultationStatusTag = ({
  consultationStatus,
  isTerminated,
}: PatientConsultationStatusTagProps) => {
  const consultationStatusTextStyle = () => {
    if (isTerminated)
      return "bg-complementary-error-100 text-complementary-error-700";

    if (isConsultationCompleted(consultationStatus))
      return "bg-success-200 text-complementary-success-950";

    return "bg-primary-200 text-primary-800";
  };

  return (
    <Tag className={consultationStatusTextStyle()}>
      <p className="first-letter:capitalize">
        {isTerminated
          ? PATIENT_TERMINATED_STATUS
          : replaceUnderscores(consultationStatus)}
      </p>
    </Tag>
  );
};
