import { Tag } from "~/common/components";
import {
  ENGAGEMENT_TYPES,
  EngagementTypes,
} from "~/providers/constants/patientSearchConstants";

interface PatientEngagementTagProps {
  engagement: EngagementTypes;
}

export const PatientEngagementTag = ({
  engagement,
}: PatientEngagementTagProps) => {
  const engagementStatusColors = {
    [ENGAGEMENT_TYPES.active]: "bg-primary-200 text-primary-800",
    [ENGAGEMENT_TYPES.inactive]: "bg-neutral-5 text-gray-70",
    [ENGAGEMENT_TYPES.consultation]: "bg-warning-complementary text-orange-900",
  };

  return (
    <Tag className={engagementStatusColors[engagement]}>
      <p className="first-letter:uppercase">{engagement}</p>
    </Tag>
  );
};
