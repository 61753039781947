import { useState } from "react";
import { useParams } from "react-router-dom";

import { Button, Typography } from "~/common/components";
import {
  BUTTON_VARIANTS,
  PATIENT_CONSULTATION_STATUS,
} from "~/common/constants";
import { PatientConsultation } from "~/common/types/consultation";
import {
  CloseAfterScreeningModal,
  PatientConsultationStatusTag,
  ProceedToIntakeFormModal,
  ToggleTerminateConsultationModal,
} from "~/providers/components";
import {
  CONSULTATION_ACTIONS,
  PatientConsultationActions,
} from "~/providers/constants";
import { useIsTeamMemberStaff } from "~/providers/hooks";
import { formatDate } from "~/providers/utils";

interface ConsultationHeaderProps {
  patientConsultation?: PatientConsultation;
}

const DateDisplay = ({ date, label }: { date?: string; label: string }) =>
  date && (
    <Typography className="text-sm text-gray-35">
      {label} <span className="text-gray-70">{formatDate(date)}</span>
    </Typography>
  );

export const ConsultationHeader = ({
  patientConsultation,
}: ConsultationHeaderProps) => {
  const { patientId } = useParams();
  const { isStaff } = useIsTeamMemberStaff();
  const [consultationNextAction, setConsultationNextAction] = useState<
    PatientConsultationActions | undefined
  >();

  const closeModal = () => setConsultationNextAction(undefined);

  if (!patientId || !patientConsultation) return null;
  const {
    id,
    status,
    end_date: dateEnded,
    start_date: dateStarted,
    terminated_at: terminatedAt,
  } = patientConsultation;

  const isTerminated = !!terminatedAt;

  const showTerminate =
    !isTerminated &&
    status !== PATIENT_CONSULTATION_STATUS.closed_after_screening &&
    status !== PATIENT_CONSULTATION_STATUS.screening_submitted &&
    status !== PATIENT_CONSULTATION_STATUS.completed;

  const postScreeningActions =
    !isTerminated && status === PATIENT_CONSULTATION_STATUS.screening_submitted;

  return (
    <>
      <div className="flex w-full justify-between">
        <div className="flex items-center gap-2">
          <div className="flex">
            <DateDisplay date={dateStarted} label="Started" />
            {dateStarted && dateEnded && <div className="mx-2 border-r" />}
            <DateDisplay date={dateEnded} label="Ended" />
          </div>
          <PatientConsultationStatusTag
            consultationStatus={status}
            isTerminated={isTerminated}
          />
        </div>

        {!isStaff &&
          (isTerminated ? (
            <Button
              variant={BUTTON_VARIANTS.outline_black}
              onClick={() =>
                setConsultationNextAction(CONSULTATION_ACTIONS.reopen)
              }
              className="w-fit"
            >
              Reopen Consultation
            </Button>
          ) : (
            <div className="flex items-center gap-2">
              {showTerminate && (
                <Button
                  className="w-fit"
                  variant={BUTTON_VARIANTS.outline_black}
                  onClick={() =>
                    setConsultationNextAction(CONSULTATION_ACTIONS.terminate)
                  }
                >
                  Terminate
                </Button>
              )}
              {postScreeningActions && (
                <>
                  <Button
                    className="w-fit"
                    variant={BUTTON_VARIANTS.outline_black}
                    onClick={() =>
                      setConsultationNextAction(
                        CONSULTATION_ACTIONS.closed_after_screening,
                      )
                    }
                  >
                    Close after screening
                  </Button>
                  <Button
                    onClick={() =>
                      setConsultationNextAction(
                        CONSULTATION_ACTIONS.proceed_to_intake,
                      )
                    }
                    className="w-fit"
                  >
                    Proceed to intake
                  </Button>
                </>
              )}
            </div>
          ))}
      </div>
      <CloseAfterScreeningModal
        onClose={closeModal}
        consultationId={id}
        isOpenModal={
          consultationNextAction === CONSULTATION_ACTIONS.closed_after_screening
        }
      />
      <ProceedToIntakeFormModal
        isOpenModal={
          consultationNextAction === CONSULTATION_ACTIONS.proceed_to_intake
        }
        handleCloseModal={closeModal}
        consultationId={id}
      />
      <ToggleTerminateConsultationModal
        isOpenModal={
          consultationNextAction === CONSULTATION_ACTIONS.terminate ||
          consultationNextAction === CONSULTATION_ACTIONS.reopen
        }
        onClose={closeModal}
        patientId={patientId}
        consultationId={id ?? ""}
        action={
          consultationNextAction === CONSULTATION_ACTIONS.terminate ||
          consultationNextAction === CONSULTATION_ACTIONS.reopen
            ? consultationNextAction
            : undefined
        }
      />
    </>
  );
};
