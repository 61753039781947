import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

import { ChevronIcon } from "~/common/components/icons";
import FilterIcon from "~/common/components/icons/FilterIcon";
import { DIRECTIONS } from "~/common/constants";
import { Group } from "~/common/utils";
import CheckBox from "~/patients/components/ui/CheckBox";

interface MultiSelectGroupListBoxProps {
  options: Group[];
  disabled?: boolean;
  selectAllLabel?: string;
  selectNoneLabel?: string;
  placeholder?: string;
  defaultValues: string[];
  containerClassName?: string;
  showFilterIcon?: boolean;
  onChange?: (value: string[]) => void;
  controllerOnChange?: (value: string[]) => void;
  handleSelectAll?: () => void;
  handleSelectNone?: () => void;
}

export const MultiSelectGroupListBox = ({
  options,
  containerClassName,
  controllerOnChange,
  showFilterIcon = true,
  defaultValues,
  disabled,
  handleSelectAll,
  handleSelectNone,
  onChange,
  placeholder,
  selectAllLabel = "Select all",
  selectNoneLabel = "Select none",
}: MultiSelectGroupListBoxProps) => {
  const optionValues = options
    .flatMap((group) => group.groupOptions)
    .map((option) => option.value);

  const hasMarkedAllOptions = (
    defaultValues: string[],
    optionValues: string[],
  ) => optionValues.every((element: string) => defaultValues.includes(element));

  const allOptionsAreSelected = hasMarkedAllOptions(
    defaultValues,
    optionValues,
  );

  return (
    <Listbox
      disabled={disabled}
      value={defaultValues}
      multiple
      onChange={(val) => {
        onChange?.(val);
        controllerOnChange?.(val);
      }}
    >
      {({ open }) => (
        <div className={twMerge("relative", containerClassName)}>
          <Listbox.Button
            aria-disabled={disabled}
            className={twMerge(
              "relative flex w-full items-center justify-between gap-2 text-nowrap rounded-lg border px-3 py-2.5 text-left sm:text-sm",
              "border-gray-70 bg-neutral-25 text-gray-70",
              "disabled:border-gray-20 disabled:bg-neutral-5 disabled:text-gray-60",
              "hover:bg-neutral-10",
            )}
          >
            <div
              className={twMerge(
                "flex items-center gap-1.5",
                disabled && "text-gray-600",
              )}
            >
              {showFilterIcon && (
                <FilterIcon
                  className={twMerge(
                    "size-6 text-neutral-80",
                    !allOptionsAreSelected && "text-primary-600",
                  )}
                />
              )}
              <p className="text-sm font-medium">{placeholder}</p>
            </div>
            <ChevronIcon
              direction={open ? DIRECTIONS.top : DIRECTIONS.down}
              className={twMerge(
                "h-3 transition-all duration-300",
                disabled && "text-gray-600",
              )}
            />
          </Listbox.Button>
          {!disabled && (
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="scrollbar-hide border-gray-25 absolute right-0 z-50 mt-1 flex max-h-60 w-full min-w-60 flex-col gap-3 overflow-auto rounded-md border border-gray-200 bg-white p-3 text-left text-base shadow-lg sm:text-sm">
                <div
                  className={twMerge(
                    "flex w-full items-center justify-between",
                  )}
                >
                  {handleSelectAll && (
                    <button
                      type="button"
                      onClick={handleSelectAll}
                      className="w-fit select-none font-medium text-gray-30"
                    >
                      {selectAllLabel}
                    </button>
                  )}
                  {handleSelectNone && (
                    <button
                      type="button"
                      onClick={handleSelectNone}
                      className="w-fit select-none font-medium text-gray-30"
                    >
                      {selectNoneLabel}
                    </button>
                  )}
                </div>
                <div className="flex flex-col gap-1">
                  {options.map(({ group, groupOptions }) => (
                    <>
                      <span
                        key={group}
                        className="text-xs font-medium text-gray-20"
                      >
                        {group}
                      </span>
                      <div className="flex w-full flex-col gap-3">
                        {groupOptions.map(({ value, label }) => (
                          <Listbox.Option
                            key={value}
                            className="relative select-none first-letter:uppercase"
                            value={value}
                          >
                            <button className="flex flex-row items-center gap-1">
                              <CheckBox
                                readOnly
                                id={value}
                                checked={defaultValues?.includes(value)}
                                parentClassName="w-fit"
                              />
                              <label className="cursor-pointer first-letter:uppercase">
                                {label}
                              </label>
                            </button>
                          </Listbox.Option>
                        ))}
                      </div>
                    </>
                  ))}
                </div>
              </Listbox.Options>
            </Transition>
          )}
        </div>
      )}
    </Listbox>
  );
};
