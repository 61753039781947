import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { reviewInteractions } from "~/providers/api";

export const useReviewInteractions = (patientId: string) => {
  const { mutate: reviewInteractionsMutation } = useMutation({
    mutationFn: () => reviewInteractions(patientId),
    onError: () => {
      toast.error("Something went wrong while reviewing the interactions.");
    },
  });

  return { reviewInteractionsMutation };
};
