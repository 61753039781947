import {
  AdmitPatientDataProps,
  ArchivePatientParams,
} from "../utils/types/patientEngagementActionsTypes";
import { api } from "./axios";

export const admitPatient = async ({
  consultationId,
  admitPatientData,
}: AdmitPatientDataProps) => {
  const { data } = await api.post<void>(
    `/v1/team-members/consultations/${consultationId}/admit`,
    admitPatientData,
  );
  return data;
};

export const archivePatient = async ({
  patientId,
  internalNote,
}: ArchivePatientParams) => {
  const { data } = await api.post<string>(
    `/v1/team-members/patients/${patientId}/archive`,
    internalNote,
  );

  return data;
};
