import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { rateSummary } from "~/providers/api";
import { SUMMARY_TYPE } from "~/providers/utils/types/patientPreviewTypes";

export const useRateConsultationSummary = () => {
  const queryClient = useQueryClient();
  const { mutate: rateSummaryMutation, isPending } = useMutation({
    mutationFn: rateSummary,
    onError: () =>
      toast.error("We had an error saving your rating. Try again later!"),
    onSuccess: async (_, payload) => {
      const queryKey =
        payload.summaryType === SUMMARY_TYPE.screening
          ? "getPatientScreeningConsultationSummary"
          : "getPatientIntakeConsultationSummary";
      await queryClient.invalidateQueries({
        queryKey: [queryKey],
      });
    },
  });

  return { rateSummaryMutation, isPending };
};
