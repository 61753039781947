import { ADMISSION_RECOMMENDATION } from "~/providers/constants";
import {
  ConsultationCompletionDataType,
  MentalStatusExaminationsQuestions,
} from "~/providers/utils";
import { ConsultationCompletionDetail } from "~/providers/utils/types/consultation";

const getMentalStatusExaminationValue = (
  value: MentalStatusExaminationsQuestions,
  completedData?: ConsultationCompletionDetail | null,
) => {
  return {
    values: completedData?.[value]?.values ?? [],
    extra_data: completedData?.[value]?.extra_data,
  };
};

const getAdmissionRecommendationValue = (value?: boolean | null) => {
  if (value === null) return null;
  return value
    ? ADMISSION_RECOMMENDATION.recommend_admission
    : ADMISSION_RECOMMENDATION.do_not_recommend_admission;
};

export const getConsultationCompletionValues = (
  completedData?: ConsultationCompletionDetail | null,
): ConsultationCompletionDataType => {
  return {
    completeAndSign: false,
    data: {
      appearance: getMentalStatusExaminationValue("appearance", completedData),
      behavior: getMentalStatusExaminationValue("behavior", completedData),
      speech: getMentalStatusExaminationValue("speech", completedData),
      mood: getMentalStatusExaminationValue("mood", completedData),
      affect: getMentalStatusExaminationValue("affect", completedData),
      thought_process: getMentalStatusExaminationValue(
        "thought_process",
        completedData,
      ),
      thought_content: getMentalStatusExaminationValue(
        "thought_content",
        completedData,
      ),
      attention_and_concentration: getMentalStatusExaminationValue(
        "attention_and_concentration",
        completedData,
      ),
      judgment_and_insight: getMentalStatusExaminationValue(
        "judgment_and_insight",
        completedData,
      ),
      diagnostic_considerations: completedData?.diagnostic_considerations ?? "",
      treatment_plan: completedData?.treatment_plan ?? "",
      refer_patient: completedData?.refer_patient ?? null,
      admission_recommended: getAdmissionRecommendationValue(
        completedData?.admission_recommended,
      ),
    },
  };
};
