import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { archivePatient } from "~/providers/api";

export const useArchiveLeadPatient = (
  patientName: string,
  onSuccess?: () => Promise<void> | void,
  onError?: () => void,
) => {
  const { mutate: archivePatientMutation, isPending } = useMutation({
    mutationFn: archivePatient,
    onSuccess: async () => {
      toast.success(`${patientName} has been archived successfully!`);

      await onSuccess?.();
    },
    onError: () => {
      onError ??
        toast.error(
          "There was an error archiving your patient. If the issue persists, please reload the page.",
        );
    },
  });

  return { archivePatientMutation, isPending };
};
