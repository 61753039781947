import { ADMISSION_RECOMMENDATION } from "~/providers/constants";
import { ConsultationCompletionDataType } from "~/providers/utils";

export const modifyConsultationCompletionData = ({
  completeAndSign,
  data,
}: ConsultationCompletionDataType) => {
  return {
    completeAndSign: completeAndSign,
    data: {
      ...data,
      admission_recommended:
        data.admission_recommended ===
        ADMISSION_RECOMMENDATION.recommend_admission,
    },
  };
};
