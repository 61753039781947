import { useDebounce } from "@uidotdev/usehooks";
import { useSearchParams } from "react-router-dom";

import { ORDER, ORDER_BY, SCRIPTS_PAGE_SIZE } from "~/common/constants";
import {
  PATIENT_FILTER_PARAMS,
  SCRIPT_FILTERS_VALUES,
} from "~/providers/constants";
import { useGetScriptsList, useGetTeamMembersList } from "~/providers/hooks";
import { generateTeamMemberOptions } from "~/providers/utils";
import Pagination from "../Pagination";
import { RequestsLayout } from "../RequestsLayout";
import { ScriptDetailsDrawer } from "../ScriptDetails/ScriptDetailsDrawer";
import { PrescriptionRequestFilters } from "./PrescriptionRequestFilters";
import { PrescriptionRequestTable } from "./PrescriptionRequestTable";

export const PrescriptionRequest = () => {
  const [params] = useSearchParams({
    [PATIENT_FILTER_PARAMS.pageSize]: SCRIPTS_PAGE_SIZE,
    [PATIENT_FILTER_PARAMS.order]: ORDER.descendant,
    [PATIENT_FILTER_PARAMS.orderBy]: ORDER_BY.creationDate,
  });

  const debouncedParams = useDebounce(params, 600);

  const {
    data: prescriptionRequests,
    isLoading,
    isFetched,
  } = useGetScriptsList(debouncedParams);

  const {
    data: prescriptions,
    page,
    total_pages,
    total_items,
    filtered_count,
  } = { ...prescriptionRequests };

  const noFilterApplied = !SCRIPT_FILTERS_VALUES.some((filter) =>
    params.has(filter),
  );
  const noPrescriptionRequestsAssigned =
    noFilterApplied && !prescriptionRequests?.data.length;

  const { data: teamMembers } = useGetTeamMembersList();
  const teamMemberOptions = generateTeamMemberOptions(teamMembers);

  const showPagination =
    !!total_pages && total_pages > 1 && filtered_count && page;

  return (
    <RequestsLayout>
      <PrescriptionRequestFilters
        isLoading={isLoading}
        disableFilters={noPrescriptionRequestsAssigned}
        filteredPatientsCount={filtered_count}
        totalPatients={total_items}
      />
      <div className="flex size-full flex-col items-end justify-between gap-3 overflow-auto">
        {!isLoading && (
          <PrescriptionRequestTable
            noPrescriptionRequestsAssigned={noPrescriptionRequestsAssigned}
            prescriptionRequests={prescriptions}
            isLoading={isLoading}
            isFetched={isFetched}
            ownersOptions={teamMemberOptions}
          />
        )}
      </div>
      {showPagination && (
        <div className="flex justify-end">
          <Pagination
            totalItems={filtered_count}
            currentPage={page}
            totalPages={total_pages}
          />
        </div>
      )}
      <ScriptDetailsDrawer />
    </RequestsLayout>
  );
};
