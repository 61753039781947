import { useEffect, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { useSearchParams } from "react-router-dom";

import { Patient } from "~/common/utils";
import { PaginatedServiceResponse } from "../api";
import { PATIENT_FILTER_PARAMS } from "../constants";
import { defaultHomeParams, PatientsFilters } from "../utils";
import { useGetPatients } from "./queries";

export const useTeamMemberHome = () => {
  const [params, setParams] = useSearchParams(defaultHomeParams);
  const debouncedParams = useDebounce(params, 600);

  const paramName = params.get(PATIENT_FILTER_PARAMS.name) ?? undefined;

  const [nameFilter, setNameFilter] = useState(paramName);

  const [patientList, setPatientList] = useState<
    PaginatedServiceResponse<Patient[]> | undefined
  >();

  const { data: patients, isLoading } = useGetPatients(debouncedParams);

  const onFilterChange = (filter: PatientsFilters) => {
    params.delete(PATIENT_FILTER_PARAMS.page);
    params.set(PATIENT_FILTER_PARAMS.filter, filter);
    setParams(params);
  };

  useEffect(() => {
    if (!nameFilter) {
      params.delete(PATIENT_FILTER_PARAMS.name);
      setParams(params);
    } else {
      params.delete(PATIENT_FILTER_PARAMS.page);
      const newParams = new URLSearchParams(params);
      newParams.set(PATIENT_FILTER_PARAMS.name, nameFilter);
      setParams(newParams);
    }
  }, [nameFilter, params, setParams]);

  useEffect(() => {
    setPatientList(patients);
  }, [patients]);

  const totalPatients = !isLoading
    ? patients?.total_items ?? 0
    : patientList?.total_items ?? 0;

  return {
    setNameFilter,
    onFilterChange,
    patients,
    isLoading,
    totalPatients,
    nameFilter,
    params,
  };
};
