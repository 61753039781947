import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { SectionHeader } from "~/common/components";
import { MultiSelectGroup } from "~/common/components/ui/multiSelectGroup";
import { scriptStatusGroup } from "~/common/constants";
import { useHandleMultipleFilters } from "~/common/hooks";
import { SCRIPT_FILTERS } from "~/providers/constants";
import { PATIENT_FILTER_PARAMS } from "~/providers/constants/patients";
import { FilterResultSummary } from "../FilterResultSummary";
import SearchBar from "../SearchBar";

interface PrescriptionRequestFiltersProps {
  isLoading: boolean;
  disableFilters: boolean;
  filteredPatientsCount?: number;
  totalPatients?: number;
}

export const PrescriptionRequestFilters = ({
  isLoading,
  disableFilters,
  filteredPatientsCount,
  totalPatients,
}: PrescriptionRequestFiltersProps) => {
  const [params, setParams] = useSearchParams();
  const paramName = params.get(SCRIPT_FILTERS.patient_name) ?? undefined;
  const [patientFilter, setPatientFilter] = useState(paramName);

  useEffect(() => {
    if (!patientFilter) {
      params.delete(SCRIPT_FILTERS.patient_name);
      setParams(params);
    } else {
      params.delete(PATIENT_FILTER_PARAMS.page);
      const newParams = new URLSearchParams(params);
      newParams.set(SCRIPT_FILTERS.patient_name, patientFilter);
      setParams(newParams);
    }
  }, [patientFilter, params, paramName, setParams]);

  const { handleMultipleFilters } = useHandleMultipleFilters();

  const handleStatusFilter = (filters: string[]) => {
    handleMultipleFilters(SCRIPT_FILTERS.status, filters);
  };

  return (
    <div className="grid justify-between gap-4 md:flex">
      <SectionHeader className="flex flex-col text-xl font-semibold lg:text-3xl">
        Prescription requests
        <FilterResultSummary
          filteredPatientsCount={filteredPatientsCount}
          totalPatients={totalPatients}
        />
      </SectionHeader>
      <div className="col-span-4 flex flex-wrap items-center justify-end gap-3 xl:flex-nowrap">
        <SearchBar
          className="w-full"
          disabled={disableFilters}
          placeholder="Search by patient name"
          isLoading={isLoading}
          value={patientFilter}
          setValue={setPatientFilter}
        />

        <MultiSelectGroup
          name="status"
          placeholder="Filter by"
          hasSelectAll={false}
          selectNoneLabel="Clear all"
          options={scriptStatusGroup}
          value={params.getAll(SCRIPT_FILTERS.status)}
          onChange={handleStatusFilter}
          disabled={disableFilters}
        />
      </div>
    </div>
  );
};
