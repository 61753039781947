import { generatePath, Link, useParams } from "react-router-dom";

import { InformationIcon } from "~/common/components";
import { PatientConsultationStatus } from "~/common/constants/consultation";
import { PatientStatus } from "~/common/utils";
import {
  getAssessmentAndPlanRoute,
  isConsultationCompleted,
  PatientParams,
} from "~/providers/utils";

interface DisabledReportMessageProps {
  patientStatus: PatientStatus;
  patientConsultationStatus?: PatientConsultationStatus;
}

export const DisabledReportMessage = ({
  patientStatus,
  patientConsultationStatus,
}: DisabledReportMessageProps) => {
  const { patientId } = useParams<keyof PatientParams>() as PatientParams;

  return (
    <div className="flex gap-2 rounded-md bg-neutral-20 p-3 font-normal text-gray-35">
      <InformationIcon />
      {isConsultationCompleted(patientConsultationStatus) ? (
        <p>
          The consultation report is being prepared and will be available
          shortly. Please refresh the screen in a few minutes.
        </p>
      ) : (
        <p>
          The consultation report will be populated after the{" "}
          <span>
            <Link
              to={generatePath(getAssessmentAndPlanRoute(patientStatus), {
                patientId,
              })}
              className="text-primary-600 underline"
            >
              Assessment & plan
            </Link>
          </span>{" "}
          is completed.
        </p>
      )}
    </div>
  );
};
