export const PATIENT_PREVIEW_CONSULTATION_TYPES = {
  screening: "screening",
  intake: "intake",
};

export type ResponseQuestionTypes =
  (typeof PATIENT_PREVIEW_CONSULTATION_TYPES)[keyof typeof PATIENT_PREVIEW_CONSULTATION_TYPES];

export const SUMMARY_TYPE = {
  intake: "intake",
  screening: "screening",
  treatment_recommendations: "treatment_recommendations",
} as const;

export type SummaryType = (typeof SUMMARY_TYPE)[keyof typeof SUMMARY_TYPE];

export interface ScoreData {
  score: number;
  label: string;
}

export interface ScoresData {
  asrs: ScoreData;
  gad7: ScoreData;
  phq9: ScoreData;
}

export interface MedicationsData {
  name: string;
  dose: string;
  frequency: string;
}

interface ClinicalDimensionsData {
  potential_areas_of_concern: string[];
  no_signs_of_concern: string[];
}

export interface ScreeningSummaryData {
  id: string;
  short_summary: string;
  long_summary: string;
  scores: ScoresData;
  significant_clinical_dimensions: ClinicalDimensionsData;
  medications?: MedicationsData[];
}

export interface SummaryFeedback {
  consultation_summary_id: string;
  consultation_type: SummaryType;
  id: string;
  is_like: boolean;
  team_member_id: string;
}

export interface PatientScreeningConsultationSummary {
  id: string;
  feedbacks: SummaryFeedback[];
  consultation_id: string;
  type: ResponseQuestionTypes;
  data: ScreeningSummaryData;
}
