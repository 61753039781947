import {
  ConsultationDetails,
  getTeamMemberConsultationBaseUrl,
} from "../utils";
import {
  AssessmentAndPlanData,
  ChangeConsultationStatusParams,
  CloseAfterScreeningParams,
  ConsultationCompletionDetail,
  UpdateConsultationDetails,
  UpsertConsultationDetailsData,
} from "../utils/types/consultation";
import { api } from "./axios";

export const createConsultationDetails = async (
  price: number,
  consultationId?: string,
) => {
  const { data } = await api.post<ConsultationDetails>(
    `${getTeamMemberConsultationBaseUrl(consultationId)}/details`,
    { price },
  );

  return data;
};

export const completeAndSignConsultationCompletionDetails = async ({
  consultationId,
  consultationDetails,
}: UpsertConsultationDetailsData<AssessmentAndPlanData>) => {
  const { data } = await api.post<ConsultationCompletionDetail>(
    `${getTeamMemberConsultationBaseUrl(
      consultationId,
    )}/completion-details/complete-and-sign`,
    consultationDetails,
  );

  return data;
};

export const updateConsultationCompletionDetails = async ({
  consultationId,
  consultationDetails,
  consultationDetailsId,
}: UpsertConsultationDetailsData<UpdateConsultationDetails>) => {
  const { data } = await api.put<ConsultationCompletionDetail>(
    `${getTeamMemberConsultationBaseUrl(
      consultationId,
    )}/completion-details/${consultationDetailsId}`,
    consultationDetails,
  );

  return data;
};

export const createConsultationCompletionDetails = async ({
  consultationId,
  consultationDetails,
}: UpsertConsultationDetailsData<UpdateConsultationDetails>) => {
  const { data } = await api.post<ConsultationCompletionDetail>(
    `${getTeamMemberConsultationBaseUrl(consultationId)}/completion-details`,
    consultationDetails,
  );

  return data;
};

export const getConsultationCompletionDetail = async (
  consultationId: string,
) => {
  const { data } = await api.get<ConsultationCompletionDetail | null>(
    `${getTeamMemberConsultationBaseUrl(
      consultationId,
    )}/completion-details/current`,
  );

  return data;
};

export const terminateConsultation = async ({
  consultationId,
  data,
}: ChangeConsultationStatusParams) => {
  await api.post<null>(
    `${getTeamMemberConsultationBaseUrl(consultationId)}/terminate`,
    data,
  );
};

export const reOpenConsultation = async ({
  consultationId,
  data,
}: ChangeConsultationStatusParams) => {
  await api.post<null>(
    `${getTeamMemberConsultationBaseUrl(consultationId)}/re-open`,
    data,
  );
};

export const referAfterScreening = async ({
  consultationId,
  data,
}: CloseAfterScreeningParams) => {
  await api.post<null>(
    `${getTeamMemberConsultationBaseUrl(consultationId)}/refer-after-screening`,
    data,
  );
};
