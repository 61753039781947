import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { CancelSubmitButtons } from "~/common/components";
import Modal from "~/common/components/Modal";
import TextArea from "~/common/components/ui/Textarea";
import { TEXT_MAX_LENGTH } from "~/common/constants";
import { Patient } from "~/common/utils";
import { useArchiveLeadPatient } from "~/providers/hooks";
import {
  optionalInternalNoteSchema,
  OptionalInternalNoteType,
  PatientData,
} from "~/providers/utils";

interface ArchivePatientModalProps {
  show: boolean;
  onClose: () => void;
  patient: PatientData | Patient;
  handleArchiveSuccessfully: () => Promise<void>;
}

export const ArchivePatientModal = ({
  onClose,
  show,
  patient,
  handleArchiveSuccessfully,
}: ArchivePatientModalProps) => {
  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm<OptionalInternalNoteType>({
    resolver: zodResolver(optionalInternalNoteSchema),
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const { archivePatientMutation, isPending } = useArchiveLeadPatient(
    patient.name,
    handleArchiveSuccessfully,
  );

  const onSubmit = (data: OptionalInternalNoteType) =>
    archivePatientMutation({ patientId: patient.id, internalNote: data });

  return (
    <Modal
      className="top-auto"
      title="Archive patient"
      description={"Are you sure you want to archive this patient?"}
      show={show}
      onClose={handleClose}
    >
      <form
        className="flex size-full flex-col gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextArea
          label="Internal note (optional)"
          placeholder=""
          rows={4}
          charactersCount={watch("internal_note")?.length}
          maxCharacters={TEXT_MAX_LENGTH}
          error={errors.internal_note?.message}
          {...register("internal_note")}
        />
        <CancelSubmitButtons
          containerClassName="flex justify-end"
          cancelClassName="w-fit"
          submitClassName="w-fit"
          handleCancel={handleClose}
          cancelButtonLabel="Cancel"
          submitButtonLabel="Confirm"
          isSubmitting={isPending}
        />
      </form>
    </Modal>
  );
};
