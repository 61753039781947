import * as React from "react";
import { twMerge } from "tailwind-merge";

import { IconProps } from "~/common/utils/types";

const FilterIcon = ({ className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    className={twMerge("size-5", className)}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2"
      d="M4 6h12m0 0a2 2 0 1 0 4 0 2 2 0 0 0-4 0Zm-8 6h12M8 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-4 6h12m0 0a2 2 0 1 0 4 0 2 2 0 0 0-4 0Z"
    ></path>
  </svg>
);

export default FilterIcon;
