import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

import { CancelSubmitButtons, LoadingState } from "~/common/components";
import Modal from "~/common/components/Modal";
import { MEDICATION_TYPES } from "~/common/constants";
import {
  MedicationBasicData,
  PatientMedicationDetailsFormValues,
} from "~/common/types";
import { scrollToBottom } from "~/common/utils";
import {
  useGetMedicationSelectOptions,
  useUpsertPatientMedication,
} from "~/providers/hooks";
import { addMedicationSchema } from "~/providers/utils";
import {
  AdditionalInformationInputs,
  MedicationDetailsInputs,
  SubmitPrescriptionInputs,
} from "./addMedicationFormComponents";

export type UpsertMedicationFormValues = z.infer<typeof addMedicationSchema>;
interface UpsertMedicationModalProps {
  show: boolean;
  handleClose: () => void;
  patientId: string;
  defaultMedication?: MedicationBasicData;
  defaultPatientMedicationDetails?: PatientMedicationDetailsFormValues;
  defaultPatientMedicationId?: string;
}

export const UpsertMedicationModal = ({
  patientId,
  show,
  handleClose,
  defaultMedication,
  defaultPatientMedicationDetails,
  defaultPatientMedicationId,
}: UpsertMedicationModalProps) => {
  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    watch,
    control,
    reset,
  } = useForm<UpsertMedicationFormValues>({
    resolver: zodResolver(addMedicationSchema),
    defaultValues: {
      medication_id: defaultMedication?.id,
      patient_medications_detail: defaultPatientMedicationDetails,
    },
  });

  const submitPrescriptionOrder = watch(
    "patient_medications_detail.submit_prescription_order",
  );

  useEffect(() => {
    submitPrescriptionOrder && scrollToBottom("bottom");
  }, [submitPrescriptionOrder]);

  const handleCloseModal = () => {
    handleClose();
    reset();
  };

  const {
    frequenciesList,
    medicationsLists,
    medicationsOptions,
    frequenciesOptions,
    loadingSelectOptions,
  } = useGetMedicationSelectOptions(show);
  const { upsertPatientMedication, isPending } = useUpsertPatientMedication({
    patientId,
    onSuccess: handleCloseModal,
    updateMedication: !!defaultMedication,
    defaultPatientMedicationId,
  });

  const onSubmit: SubmitHandler<UpsertMedicationFormValues> = (data) => {
    upsertPatientMedication(data);
  };

  const medication_id = watch("medication_id");
  const medicationTypeSelected = medicationsOptions.find(
    (med) => med.value === medication_id,
  )?.type;
  const enableMedicationChangeOrders =
    medicationTypeSelected === MEDICATION_TYPES.benzodiazepine ||
    medicationTypeSelected === MEDICATION_TYPES.antidepressant ||
    medicationTypeSelected === MEDICATION_TYPES.psychostimulant;

  return (
    <Modal
      title={defaultMedication ? "Modify medication" : "Add new medication"}
      show={show}
      onClose={handleCloseModal}
      className="max-h-full lg:w-2/5"
    >
      {loadingSelectOptions ? (
        <LoadingState />
      ) : (
        <form
          className="flex w-full flex-col items-end gap-3 overflow-y-auto"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex w-full flex-col gap-3 overflow-y-auto">
            <MedicationDetailsInputs
              watch={watch}
              errors={errors}
              register={register}
              control={control}
              updateMedication={!!defaultMedication}
              frequenciesList={frequenciesList?.data}
              medicationsLists={medicationsLists?.data}
              medicationsOptions={medicationsOptions}
              frequenciesOptions={frequenciesOptions}
            />
            <div className="flex flex-col gap-3">
              <AdditionalInformationInputs
                register={(field) =>
                  register(`patient_medications_detail.${field}`)
                }
                watch={watch}
                errors={errors}
              />

              <div id="bottom" />

              <SubmitPrescriptionInputs
                register={(field) =>
                  register(`patient_medications_detail.${field}`)
                }
                watch={watch}
                errors={errors}
                enableMedicationChangeOrders={enableMedicationChangeOrders}
              />
            </div>
          </div>
          <CancelSubmitButtons
            disableSubmit={!!defaultMedication && !isDirty}
            isSubmitting={isPending}
            handleCancel={handleCloseModal}
            variant="right-outline-black"
            submitButtonLabel="Save"
          />
        </form>
      )}
    </Modal>
  );
};
