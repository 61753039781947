import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DeepPartial } from "react-hook-form";
import { toast } from "react-toastify";

import { updateConsultationReport } from "~/providers/api/consultationReport";
import { PatientConsultationReportData } from "~/providers/utils/types/consultation";

interface UseUpdateConsultationReportProps {
  consultationId?: string;
  showSuccessMessage?: boolean;
}
export const useUpdateConsultationReport = ({
  consultationId,
  showSuccessMessage,
}: UseUpdateConsultationReportProps) => {
  const queryClient = useQueryClient();

  const { mutate: updateConsultationReportMutation, isPending } = useMutation({
    mutationFn: (
      consultationReport: DeepPartial<PatientConsultationReportData>,
    ) => updateConsultationReport(consultationReport, consultationId),
    onError: () =>
      toast.error(
        "We shoot trouble updating the consultation report, if the issue persists please reload the page!",
      ),
    onSuccess: async () => {
      showSuccessMessage &&
        toast.success("Consultation report updated successfully!");
      await queryClient.invalidateQueries({
        queryKey: ["getConsultationReport", consultationId],
      });
    },
  });

  return { updateConsultationReportMutation, isPending };
};
