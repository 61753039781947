export const joinParams = (params: Record<string, string[]>) => {
  return Object.entries(params)
    .flatMap(([key, values]) => values.map((value) => `${key}=${value}`))
    .join("&");
};

export const generateQueryString = (
  baseUrl: string,
  params: Record<string, string[]>,
) => {
  return `${baseUrl}?${joinParams(params)}`;
};
