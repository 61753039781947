import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFormGetValues,
  UseFormRegister,
} from "react-hook-form";

import { ErrorText, Typography } from "~/common/components";
import { MultiCheckOption } from "~/common/components/MultiCheckOption";
import TextArea from "~/common/components/ui/Textarea";
import { ConsultationCompletionDataType } from "~/providers/utils";
import {
  CompletedMultiCheckAnswer,
  MultiCheckMentalStatusOptions,
  MultiCheckQuestionData,
} from "~/providers/utils/mentalStatusExaminations";

export interface MentalStatusExaminationFormProps {
  question: MultiCheckQuestionData;
  completedAnswer?: CompletedMultiCheckAnswer;
  getValues: UseFormGetValues<ConsultationCompletionDataType>;
  register: UseFormRegister<ConsultationCompletionDataType>;
  errors: FieldErrors<ConsultationCompletionDataType>;
  control: Control<ConsultationCompletionDataType>;
  isDisabledForm: boolean;
}

export const MentalStatusExaminationForm = ({
  question,
  register,
  getValues,
  errors,
  control,
  isDisabledForm,
}: MentalStatusExaminationFormProps) => {
  const { title, commonExtraDataLabel, key, options } = question;

  const selectedValues = getValues(`data.${key}.values`) ?? [];

  const { append, remove } = useFieldArray({
    control,
    name: `data.${key}.values`,
  });

  const handleCheck = (
    option: MultiCheckMentalStatusOptions,
    selectedIndex: number,
  ) => {
    selectedIndex !== -1
      ? remove(selectedIndex)
      : append({ value: option.value });
  };

  const commonExtraDataRequired = selectedValues?.some(
    (value) =>
      options.find((option) => option.value === value.value)
        ?.asksCommonExtraData,
  );
  return (
    <div className="space-y-3 rounded-md bg-primary-100 p-4" key={key}>
      <div>
        <Typography>{title}</Typography>
      </div>

      <div className="flex flex-col gap-2 pb-6">
        {options.map((option) => {
          const selectedIndex = selectedValues?.findIndex(
            (item) => item.value === option.value,
          );
          const extraDataErrorMessage =
            errors?.data?.[key]?.values?.[selectedIndex]?.extra_data?.message ??
            errors?.data?.[key]?.extra_data?.message;

          return (
            <div key={`${key}-${option.value}`} className="flex flex-col gap-2">
              <MultiCheckOption
                id={`${key}-${option.value}`}
                option={option}
                onChange={() => handleCheck(option, selectedIndex)}
                register={(index) =>
                  register(`data.${key}.values.${index}.extra_data`)
                }
                otherErrorMessage={extraDataErrorMessage}
                selectedIndex={selectedIndex}
                isDisabled={isDisabledForm}
              />
            </div>
          );
        })}
        <ErrorText>{errors?.data?.[key]?.values?.message}</ErrorText>
      </div>

      {commonExtraDataRequired && (
        <TextArea
          key={`${key}-common-extra-data`}
          rows={4}
          label={commonExtraDataLabel}
          placeholder="Please describe further"
          className="text-base font-normal placeholder:text-base placeholder:not-italic placeholder:text-gray-60"
          disabled={isDisabledForm}
          {...register(`data.${key}.extra_data`)}
        />
      )}
    </div>
  );
};
