import { Outlet } from "react-router-dom";

import NavMenu from "~/common/components/ui/NavMenu";
import { useGetSettingsLayoutMenuOptions } from "~/providers/hooks";
import { useUiStore } from "../stores";

export const SettingsLayout = () => {
  const { setSideBarOpen } = useUiStore();

  const { menuOptions } = useGetSettingsLayoutMenuOptions();

  return (
    <div className="flex h-screen-calculated w-full flex-col gap-6 text-sm md:gap-8">
      <NavMenu
        title="Settings"
        menuOptions={menuOptions}
        handleOpenSideBar={() => setSideBarOpen(true)}
      />
      <Outlet />
    </div>
  );
};
