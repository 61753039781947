import React from "react";
import { twMerge } from "tailwind-merge";

import { ErrorText, SectionHeader } from "~/common/components";
import { replaceUnderscores } from "~/common/utils";
import RadioButtonOption from "~/patients/components/ui/RadioButtonOption";
import { REFER_PATIENT_PROVIDED_VALUES } from "~/providers/constants";

export interface ReferralRadioGroupProps {
  name: string;
  onChange: () => void;
  error?: string;
}

export const ReferralRadioGroup = ({
  name,
  onChange,
  error,
}: ReferralRadioGroupProps) => (
  <div className="flex w-full flex-col gap-4 rounded-md bg-neutral-20 p-4">
    <SectionHeader className="font-sans text-base">Referral</SectionHeader>
    <div className="flex w-full flex-col gap-2.5">
      <div className={twMerge("flex flex-col", !!error && "gap-2")}>
        <div className="flex w-full gap-3">
          {REFER_PATIENT_PROVIDED_VALUES.map((value) => (
            <RadioButtonOption
              key={value}
              label={replaceUnderscores(value)}
              value={value}
              variant="simple"
              name={name}
              onChange={onChange}
            />
          ))}
        </div>
        {error && <ErrorText>{error}</ErrorText>}
      </div>
    </div>
  </div>
);
