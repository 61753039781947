import { twMerge } from "tailwind-merge";

import { Button, NoteIcon } from "~/common/components";
import { ENGAGEMENT_TYPES } from "~/providers/constants";
import { useGetCurrentTeamMember } from "~/providers/hooks";
import {
  getPatientEngagement,
  hasFullAccess,
  isConsultationCompleted,
  patientCanBeArchived,
  PatientData,
} from "~/providers/utils";
import {
  PATIENT_ACTIONS,
  PatientActions,
} from "~/providers/utils/types/patientEngagementActionsTypes";

interface PatientProfileActionButtonsProps {
  patient: PatientData;
  isStaff: boolean;
  setShowActionModal: (action: PatientActions) => void;
}

export const PatientProfileActionButtons = ({
  patient,
  isStaff,
  setShowActionModal,
}: PatientProfileActionButtonsProps) => {
  const { data: teamMember } = useGetCurrentTeamMember();
  const isTerminated = !!patient.consultation?.terminated_at;

  const isPatientConsultationComplete = isConsultationCompleted(
    patient.consultation?.status,
  );

  const isPatientEngagementConsultation =
    getPatientEngagement(patient) === ENGAGEMENT_TYPES.consultation;

  const archiveButtonEnabled = patientCanBeArchived(patient);

  const admitButtonEnabled =
    hasFullAccess(teamMember?.user_permissions) &&
    isPatientEngagementConsultation &&
    isPatientConsultationComplete;

  const isInactivePatient =
    getPatientEngagement(patient) === ENGAGEMENT_TYPES.inactive;

  const disableActions =
    getPatientEngagement(patient) === ENGAGEMENT_TYPES.inactive ||
    isTerminated ||
    isStaff;

  if (disableActions) return null;

  return (
    <div className="flex gap-3">
      {!isInactivePatient && (
        <Button
          className="w-fit"
          variant="outline-black"
          onClick={() => setShowActionModal(PATIENT_ACTIONS.new_note)}
          left={<NoteIcon className="text-gray-700" />}
        >
          New note
        </Button>
      )}
      <div
        className={twMerge(
          "flex gap-3",
          (archiveButtonEnabled || admitButtonEnabled) &&
            "border-l border-primary-200 pl-3",
        )}
      >
        {archiveButtonEnabled && (
          <Button
            className="w-fit"
            variant="outline-black"
            onClick={() => setShowActionModal(PATIENT_ACTIONS.archive)}
          >
            Archive
          </Button>
        )}
        {admitButtonEnabled && (
          <Button
            className="w-fit"
            onClick={() => setShowActionModal(PATIENT_ACTIONS.admit)}
          >
            Admit
          </Button>
        )}
      </div>
    </div>
  );
};
