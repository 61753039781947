import {
  CHECK_IN_CODES,
  CHECKIN_STATUS,
  checkInUtilities,
  formatMedicationName,
  INTERACTION_CREATION_TYPES,
  replaceUnderscores,
} from "~/common/utils";
import { ACTIVITY_FEED_CODES } from "~/providers/constants";
import { isBundleFeedContext } from "../orders";
import { PatientFeed } from "../types/interactions";
import { CheckInSubmittedFeedContext } from "../types/patientFeeds";

export const activityFeedCardDescriptionByCode = {};

const { formatCheckInCode } = checkInUtilities();
export const getFeedDescription = (feed: PatientFeed) => {
  if (
    feed.code === ACTIVITY_FEED_CODES.checkin_ordered &&
    feed.context.checkin_code === CHECK_IN_CODES.schedule_appointment &&
    feed.context.status === CHECKIN_STATUS.assigned
  )
    return `Appointment (${feed.context.data?.extra_data?.duration})`;

  if (
    feed.code === ACTIVITY_FEED_CODES.checkin_missed &&
    feed.context.checkin_code === CHECK_IN_CODES.schedule_appointment &&
    feed.context.status === CHECKIN_STATUS.missed
  )
    return "Appointment schedule";

  if (
    (feed.code === ACTIVITY_FEED_CODES.checkin_submitted ||
      feed.code === ACTIVITY_FEED_CODES.checkin_ordered ||
      feed.code === ACTIVITY_FEED_CODES.checkin_missed ||
      feed.code === ACTIVITY_FEED_CODES.recurrent_checkin_ordered) &&
    !(feed.context.checkin_code === CHECK_IN_CODES.schedule_appointment)
  ) {
    if (
      feed.context.checkin_code === CHECK_IN_CODES.status_check &&
      (feed.context as CheckInSubmittedFeedContext).creation_type ===
        INTERACTION_CREATION_TYPES.patient_created
    )
      return "Ad hoc Status check";
    return formatCheckInCode(feed.context.checkin_code);
  }

  if (
    feed.code === ACTIVITY_FEED_CODES.bundle_ordered &&
    isBundleFeedContext(feed.context)
  ) {
    return `${feed.context.bundle.patient_medication?.medication.type} medication change`;
  }

  if (
    feed.code === ACTIVITY_FEED_CODES.medication_added ||
    feed.code === ACTIVITY_FEED_CODES.medication_modified ||
    feed.code === ACTIVITY_FEED_CODES.medication_discontinued
  ) {
    return `${formatMedicationName(feed.context.medication)} ${
      feed.context.data.medication_strength.amount
    }${feed.context.data.medication_strength.unit}, ${
      feed.context.data.frequency.description
    }`;
  }

  if (
    feed.code === ACTIVITY_FEED_CODES.prescription_requested ||
    feed.code === ACTIVITY_FEED_CODES.prescription_sent_to_pharmacy ||
    feed.code === ACTIVITY_FEED_CODES.prescription_cancelled
  )
    return formatMedicationName(
      feed.context.data.patient_medication?.medication,
    );

  if (
    feed.code === ACTIVITY_FEED_CODES.prescription_paused ||
    feed.code === ACTIVITY_FEED_CODES.prescription_resumed
  ) {
    return formatMedicationName(feed.context.data.medication);
  }

  if (feed.code === ACTIVITY_FEED_CODES.closed_after_screening) {
    return replaceUnderscores(feed.code);
  }

  if (feed.code === ACTIVITY_FEED_CODES.consultation_terminated) {
    return "Terminated";
  }
};
