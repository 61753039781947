export const PatientFeedIcon = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="32" height="32" rx="16" fill="#ECEFFD" />
    <path
      d="M16.0007 18.4798C18.7333 18.4798 20.9486 16.2646 20.9486 13.5319C20.9486 10.7992 18.7333 8.58398 16.0007 8.58398C13.268 8.58398 11.0527 10.7992 11.0527 13.5319C11.0527 16.2646 13.268 18.4798 16.0007 18.4798ZM16.0007 18.4798C11.6284 18.4798 8.08398 21.1381 8.08398 24.4173M16.0007 18.4798C20.3729 18.4798 23.9173 21.1381 23.9173 24.4173"
      stroke="#8093F2"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
