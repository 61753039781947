import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { terminateConsultation } from "~/providers/api";

interface UseTerminateConsultationProps {
  patientId: string;
  onSuccess: () => void;
}
export const useTerminateConsultation = ({
  patientId,
  onSuccess,
}: UseTerminateConsultationProps) => {
  const queryClient = useQueryClient();
  const { mutate: terminateConsultationMutation, isPending } = useMutation({
    mutationFn: terminateConsultation,
    onError: () =>
      toast.error(
        "There was an issue terminating the consultation. Please try again later.",
      ),
    onSuccess: async () => {
      toast.success("Consultation terminated successfully!");
      await queryClient.invalidateQueries({
        queryKey: ["getPatient", patientId],
      });
      onSuccess();
    },
  });

  return { terminateConsultationMutation, isPending };
};
