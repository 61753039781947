import {
  PATIENT_CONSULTATION_STATUS,
  PatientConsultationStatus,
} from "~/common/constants/consultation";
import { Patient } from "~/common/utils";
import { ENGAGEMENT_TYPES } from "~/providers/constants";
import { isConsultationCompleted, PatientData } from "~/providers/utils";

export const patientCompletedIntake = (
  consultationStatus?: PatientConsultationStatus,
) =>
  consultationStatus === PATIENT_CONSULTATION_STATUS.intake_submitted ||
  consultationStatus === PATIENT_CONSULTATION_STATUS.appointment_scheduled ||
  consultationStatus === PATIENT_CONSULTATION_STATUS.completed;

export const patientCompletedScreening = (
  consultationStatus?: PatientConsultationStatus,
) =>
  consultationStatus === PATIENT_CONSULTATION_STATUS.intake_not_started ||
  consultationStatus === PATIENT_CONSULTATION_STATUS.screening_submitted ||
  consultationStatus === PATIENT_CONSULTATION_STATUS.intake_started ||
  consultationStatus === PATIENT_CONSULTATION_STATUS.closed_after_screening ||
  patientCompletedIntake(consultationStatus);

export const getPatientEngagement = (patient: PatientData | Patient) => {
  if (patient.archived_at) return ENGAGEMENT_TYPES.inactive;

  return patient.status;
};

export const patientCanBeArchived = (patient: PatientData | Patient) => {
  const consultationStatus = patient.consultation?.status;

  return (
    consultationStatus === PATIENT_CONSULTATION_STATUS.closed_after_screening ||
    (getPatientEngagement(patient) === ENGAGEMENT_TYPES.consultation &&
      isConsultationCompleted(consultationStatus))
  );
};
