import { useDebounce } from "@uidotdev/usehooks";
import { useSearchParams } from "react-router-dom";

import { useHandleMultipleFilters } from "~/common/hooks";
import Pagination from "../components/Pagination";
import { PatientSearchFilters } from "../components/patientSearch/PatientSearchFilters";
import { PatientSearchTable } from "../components/patientSearch/PatientSearchTable";
import { PATIENT_FILTER_PARAMS } from "../constants";
import { useGetPatients } from "../hooks";

export const PatientSearch = () => {
  const [params, setSearchParams] = useSearchParams();

  const newParams = new URLSearchParams(params);
  if (!newParams.has(PATIENT_FILTER_PARAMS.isSearch)) {
    newParams.set(PATIENT_FILTER_PARAMS.isSearch, "true");
    setSearchParams(newParams, { replace: true });
  }

  const debouncedParams = useDebounce(params, 600);
  const { data: patients, isLoading } = useGetPatients(debouncedParams);

  const { data: patientsData } = { ...patients };

  const { listPatientFilterApplied } = useHandleMultipleFilters();

  const hasPatients = !!patientsData?.length || listPatientFilterApplied;

  return (
    <div className="flex h-full w-full flex-col gap-4 px-6 py-8 text-sm md:px-14">
      <PatientSearchFilters
        totalPatients={patients?.total_items}
        isLoading={isLoading}
        disableFilters={!hasPatients}
        filteredPatientsCount={patients?.filtered_count}
      />
      <PatientSearchTable patients={patientsData ?? []} isLoading={isLoading} />
      {patients && patients.total_pages > 1 && (
        <div className="flex w-full justify-end">
          <Pagination
            totalItems={patients.filtered_count}
            currentPage={patients.page}
            totalPages={patients.total_pages}
          />
        </div>
      )}
    </div>
  );
};
