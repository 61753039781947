import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useOutlet, useParams } from "react-router-dom";

import NavMenu from "~/common/components/ui/NavMenu";
import {
  AdmitPatientModal,
  ArchivePatientModal,
  NewNoteModal,
  PatientEngagementTag,
  PatientHeader,
  PatientProfileActionButtons,
} from "~/providers/components";
import { useGetPatient, useIsTeamMemberStaff } from "~/providers/hooks";
import { useUiStore } from "~/providers/stores";
import {
  getAge,
  getFullName,
  getPatientEngagement,
  getPatientProfileMenuOptions,
} from "~/providers/utils";
import {
  PATIENT_ACTIONS,
  PatientActions,
} from "~/providers/utils/types/patientEngagementActionsTypes";

export const PatientProfileLayout = () => {
  const outlet = useOutlet();
  const { setSideBarOpen } = useUiStore();
  const { isStaff } = useIsTeamMemberStaff();
  const [showActionModal, setShowActionModal] = useState<
    PatientActions | undefined
  >(undefined);
  const closeModal = () => setShowActionModal(undefined);
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { patientId } = useParams();
  const { data: patient } = useGetPatient(patientId);

  if (!patient || !patientId) {
    return null;
  }

  const menuOptions = getPatientProfileMenuOptions(
    navigate,
    patient.id,
    patient.status,
    !!patient.consultation,
  );

  const patientFullName = getFullName(patient?.name, patient?.last_name);
  const patientAge = getAge(patient?.birthday);
  const hasBirthday = !!patient?.birthday;
  const patientTitle = `${patientFullName} ${
    hasBirthday ? `(${patientAge})` : ""
  }`;

  const handleArchiveSuccessfully = async () => {
    await queryClient.invalidateQueries({
      queryKey: ["getPatient", patient.id],
    });
    closeModal();
  };

  return (
    <>
      <div className="flex h-screen-calculated w-full flex-col text-sm">
        <NavMenu
          headerInfo={<PatientHeader name={patientFullName} />}
          title={patientTitle}
          menuOptions={menuOptions}
          handleOpenSideBar={() => setSideBarOpen(true)}
          tag={
            <PatientEngagementTag engagement={getPatientEngagement(patient)} />
          }
          actionButton={
            <PatientProfileActionButtons
              patient={patient}
              isStaff={isStaff}
              setShowActionModal={setShowActionModal}
            />
          }
        />
        <div className="relative h-full overflow-auto">{outlet}</div>
      </div>
      <NewNoteModal
        show={showActionModal === PATIENT_ACTIONS.new_note}
        onClose={closeModal}
        patientId={patient.id}
      />
      <ArchivePatientModal
        show={showActionModal === PATIENT_ACTIONS.archive}
        onClose={closeModal}
        patient={patient}
        handleArchiveSuccessfully={handleArchiveSuccessfully}
      />
      <AdmitPatientModal
        show={showActionModal === PATIENT_ACTIONS.admit}
        onClose={closeModal}
        patient={patient}
      />
    </>
  );
};
