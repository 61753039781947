import { useQuery } from "@tanstack/react-query";

import { getConsultationCompletionDetail } from "~/providers/api/consultationDetails";

export const useGetConsultationCompletionDetails = (
  consultationId?: string,
  enabled = true,
) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getConsultationCompletionDetail(consultationId ?? ""),
    queryKey: ["getConsultationCompletionDetail", consultationId],
    enabled: !!consultationId && enabled,
  });
  return { data, isLoading };
};
