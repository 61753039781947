import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { referAfterScreening } from "~/providers/api";

interface UseReferAfterScreeningProps {
  patientId: string;
  onSuccess?: () => void;
}

export const useReferAfterScreening = ({
  patientId,
  onSuccess,
}: UseReferAfterScreeningProps) => {
  const queryClient = useQueryClient();
  const { mutate: referAfterScreeningMutation, isPending } = useMutation({
    mutationFn: referAfterScreening,
    onError: () =>
      toast.error(
        "There was an issue referring the patient. Please try again later.",
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getPatient", patientId],
      });
      onSuccess?.();
    },
  });

  return { referAfterScreeningMutation, isPending };
};
