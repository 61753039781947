import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";

import { EmptyState, LoadingState } from "~/common/components";
import { MergedMedicationRecord } from "~/common/types";
import {
  useGetPatientFeeds,
  useIsTeamMemberStaff,
  useReviewInteractions,
} from "~/providers/hooks";
import { generatePatientCheckinOrder } from "~/providers/utils";
import { PatientFeed } from "~/providers/utils/types/interactions";
import {
  BundleFeed,
  CheckInOrderedFeed,
  CheckInSubmittedFeed,
  RecurrentCheckInOrderedFeed,
  SelectedPatientCheckinType,
} from "~/providers/utils/types/patientFeeds";
import { PatientCheckinDrawer } from "../patientCheckins";
import { ActivityFeedCard } from "./activityFeed/ActivityFeedCard";
import { ActivityFeedOrderDetailsDrawer } from "./activityFeed/ActivityFeedOrderDetailsDrawer";
import { MedicationDetailsDrawer } from "./medications";

interface ActivityFeedProps {
  patientId: string;
}

export interface PatientMedicationDetailsDrawerProps {
  medication: MergedMedicationRecord | undefined;
  medicationName: string;
}

export interface SelectedPatientCheckin {
  feed:
    | CheckInOrderedFeed
    | RecurrentCheckInOrderedFeed
    | CheckInSubmittedFeed
    | BundleFeed;
  type: SelectedPatientCheckinType;
  isProviderOrdered: boolean;
}

export const ActivityFeed = ({ patientId }: ActivityFeedProps) => {
  const { isStaff } = useIsTeamMemberStaff();

  const [selectedPatientCheckin, setSelectedPatientCheckin] =
    useState<SelectedPatientCheckin>();

  const [selectedMedication, setSelectedMedication] = useState<
    PatientMedicationDetailsDrawerProps | undefined
  >();

  const { data, loadMore, hasNextPage, isFetched, isLoading } =
    useGetPatientFeeds(patientId);

  const { reviewInteractionsMutation } = useReviewInteractions(patientId);

  useEffect(() => {
    if (isFetched) {
      !isStaff && reviewInteractionsMutation();
    }
  }, [reviewInteractionsMutation, isFetched, isStaff]);

  const totalInteractions = data?.pages[0]?.filtered_count;

  if (isLoading) {
    return <LoadingState className="h-full" />;
  }

  if (!totalInteractions || totalInteractions === 0) {
    return <EmptyState message="There is no recent activity to show." />;
  }

  const currentOrder =
    selectedPatientCheckin &&
    generatePatientCheckinOrder(selectedPatientCheckin);

  return (
    <InfiniteScroll
      loadMore={loadMore}
      hasMore={hasNextPage}
      loader={<LoadingState key={patientId} />}
      useWindow={false}
    >
      <div className="flex w-full flex-col gap-3 px-14 py-5">
        {data?.pages.flatMap((pageData) =>
          pageData.data.map((feedOption: PatientFeed) => (
            <ActivityFeedCard
              key={feedOption.id}
              feedOption={feedOption}
              viewPatientCheckInDetails={setSelectedPatientCheckin}
              viewMedicationDetails={(
                medicationData: PatientMedicationDetailsDrawerProps,
              ) => setSelectedMedication(medicationData)}
            />
          )),
        )}
      </div>
      <ActivityFeedOrderDetailsDrawer
        activityFeedOrder={currentOrder}
        handleClose={() => {
          setSelectedPatientCheckin(undefined);
        }}
        allowDeleteOrder={false}
      />
      <PatientCheckinDrawer
        handleClose={() => setSelectedPatientCheckin(undefined)}
        patientCheckIn={selectedPatientCheckin}
      />
      <MedicationDetailsDrawer
        medication={selectedMedication?.medication}
        medicationName={selectedMedication?.medicationName ?? ""}
        handleClose={() => setSelectedMedication(undefined)}
      />
    </InfiniteScroll>
  );
};
