import {
  PATIENT_TERMINATED_STATUS,
  PatientConsultationStatus,
} from "~/common/constants";
import { replaceUnderscores } from "~/common/utils";

export const getConsultationStatusText = (
  isTerminated?: boolean,
  consultationStatus?: PatientConsultationStatus,
) => {
  if (isTerminated) {
    return PATIENT_TERMINATED_STATUS;
  }

  return consultationStatus ? replaceUnderscores(consultationStatus) : "-";
};
