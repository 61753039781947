import { SectionHeader, TopBar } from "~/common/components";
import { MultiSelectGroup } from "~/common/components/ui/multiSelectGroup";
import { useHandlePatientSearchFilters } from "~/providers/hooks/useHandlePatientSearchFilters";
import { useUiStore } from "~/providers/stores";
import { FilterResultSummary } from "../FilterResultSummary";
import SearchBar from "../SearchBar";

interface PatientSearchFiltersProps {
  isLoading?: boolean;
  disableFilters?: boolean;
  totalPatients?: number;
  filteredPatientsCount?: number;
}

export const PatientSearchFilters = ({
  isLoading,
  disableFilters,
  totalPatients = 0,
  filteredPatientsCount,
}: PatientSearchFiltersProps) => {
  const { setSideBarOpen } = useUiStore();

  const {
    handleNameFilterChange,
    handlePatientSearchFilters,
    currentEngagementFilters,
    nameFilter,
    patientSearchFiltersOptions,
  } = useHandlePatientSearchFilters();

  return (
    <div className="grid justify-between gap-4 md:flex">
      <div className="flex items-center gap-4">
        <TopBar handleOpenSideBar={() => setSideBarOpen(true)} />
        <SectionHeader className="flex flex-col text-xl font-semibold xl:text-3xl">
          <h1>Patients search</h1>
          <FilterResultSummary
            filteredPatientsCount={filteredPatientsCount}
            totalPatients={totalPatients}
          />
        </SectionHeader>
      </div>

      <div className="flex flex-wrap gap-4 lg:flex-nowrap">
        <SearchBar
          className="w-full"
          disabled={disableFilters}
          isLoading={isLoading}
          value={nameFilter}
          placeholder="Search by patient name"
          setValue={handleNameFilterChange}
        />
        <div className="flex w-full items-center justify-end md:w-fit">
          <MultiSelectGroup
            onChange={handlePatientSearchFilters}
            disabled={disableFilters}
            value={currentEngagementFilters}
            placeholder="Filter by"
            name="patientSearch"
            options={patientSearchFiltersOptions}
          />
        </div>
      </div>
    </div>
  );
};
