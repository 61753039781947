import { useParams } from "react-router-dom";

import {
  PatientPreviewCurrentMedications,
  PatientPreviewFunctionalDomains,
  PatientPreviewSelfReportedScores,
  PatientPreviewSignificantClinicalDimension,
  PatientPreviewTextInfo,
} from "~/providers/components/patientPreview";
import { PatientPreviewSymptomProgression } from "~/providers/components/patientPreview/PatientPreviewSymptomProgression";
import { useGetPatientInteractionsActions } from "~/providers/hooks";
import { getConsultationSummaryData, PatientParams } from "~/providers/utils";
import { PatientIntakeConsultationSummary } from "~/providers/utils/types/patientIntakePreviewTypes";
import {
  PatientScreeningConsultationSummary,
  SUMMARY_TYPE,
} from "~/providers/utils/types/patientPreviewTypes";

interface PatientPreviewSectionsProps {
  screeningSummary: PatientScreeningConsultationSummary;
  intakeSummary?: PatientIntakeConsultationSummary;
}

export const PatientPreviewSections = ({
  screeningSummary,
  intakeSummary,
}: PatientPreviewSectionsProps) => {
  const { patientId } = useParams<keyof PatientParams>() as PatientParams;

  const {
    screeningSummaryId,
    intakeSummaryId,
    isSummaryLiked,
    isTreatmentRecommendationsLiked,
    longSummary,
    significantClinicalDimensions,
    medications,
    scores,
    symptomProgression,
    functionalDomains,
    treatmentRecommendations,
  } = getConsultationSummaryData({ screeningSummary, intakeSummary });

  const { showRateButtons, summaryType, handleRateSummary, isRatePending } =
    useGetPatientInteractionsActions({
      patientId,
      screeningSummaryId,
      intakeSummaryId,
    });

  const {
    potential_areas_of_concern: potentialAreasOfConcern,
    no_signs_of_concern: noSignsOfConcern,
  } = significantClinicalDimensions;

  return (
    <div className="flex flex-col gap-6 pt-6">
      <PatientPreviewTextInfo
        showRateButtons={showRateButtons}
        title="Case summary"
        info={longSummary}
        summaryType={summaryType}
        onRate={handleRateSummary}
        isLike={isSummaryLiked}
        isRatePending={isRatePending}
      />

      <PatientPreviewCurrentMedications medications={medications} />

      {symptomProgression && (
        <PatientPreviewSymptomProgression
          symptomProgression={symptomProgression}
        />
      )}

      {functionalDomains && (
        <PatientPreviewFunctionalDomains
          functionalDomains={functionalDomains}
        />
      )}

      {treatmentRecommendations && (
        <PatientPreviewTextInfo
          showRateButtons={showRateButtons}
          title="Treatment recommendations"
          info={treatmentRecommendations}
          summaryType={SUMMARY_TYPE.treatment_recommendations}
          onRate={handleRateSummary}
          isLike={isTreatmentRecommendationsLiked}
          isRatePending={isRatePending}
        />
      )}

      <PatientPreviewSignificantClinicalDimension
        potentialAreasOfConcern={potentialAreasOfConcern}
        noSignsOfConcern={noSignsOfConcern}
      />

      <PatientPreviewSelfReportedScores scores={scores} />
    </div>
  );
};
