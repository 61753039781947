import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { CancelSubmitButtons } from "~/common/components";
import Modal from "~/common/components/Modal";
import TextArea from "~/common/components/ui/Textarea";
import { TEXT_MAX_LENGTH } from "~/common/constants";
import { useReferAfterScreening } from "~/providers/hooks";
import {
  closeAfterScreeningSchema,
  CloseAfterScreeningType,
} from "~/providers/utils";
import { ReferralRadioGroup } from "./ReferralRadioGroup";

interface CloseAfterScreeningModalProps {
  onClose: () => void;
  isOpenModal: boolean;
  consultationId?: string;
}

export const CloseAfterScreeningModal = ({
  onClose,
  isOpenModal,
  consultationId,
}: CloseAfterScreeningModalProps) => {
  const { patientId } = useParams();

  const handleCloseModal = () => {
    onClose();
    reset();
  };
  const { referAfterScreeningMutation, isPending } = useReferAfterScreening({
    patientId: patientId!,
    onSuccess: handleCloseModal,
  });

  const modalDescription =
    "This will close the consultation. Please provide any necessary referral(s) off platform if needed. ";

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<CloseAfterScreeningType>({
    resolver: zodResolver(closeAfterScreeningSchema),
  });

  const onSubmit: SubmitHandler<CloseAfterScreeningType> = (data) =>
    consultationId &&
    referAfterScreeningMutation({
      consultationId,
      data,
    });
  if (!patientId || !consultationId) return null;

  return (
    <Modal
      title="Close after screening"
      description={modalDescription}
      show={isOpenModal}
      onClose={handleCloseModal}
    >
      <form
        className="flex size-full flex-col gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="referral"
          control={control}
          render={({ field }) => (
            <>
              <ReferralRadioGroup
                onChange={field.onChange}
                name={field.name}
                error={errors.referral?.message}
              />
            </>
          )}
        />

        <TextArea
          label="Internal note"
          placeholder=""
          charactersCount={watch("internal_note")?.length}
          maxCharacters={TEXT_MAX_LENGTH}
          error={errors.internal_note?.message}
          {...register("internal_note")}
        />
        <CancelSubmitButtons
          containerClassName="flex justify-end"
          cancelClassName="w-fit"
          submitClassName="w-fit"
          handleCancel={handleCloseModal}
          cancelButtonLabel="Cancel"
          isSubmitting={isPending}
          submitButtonLabel="Close"
        />
      </form>
    </Modal>
  );
};
