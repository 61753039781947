import { useParams } from "react-router-dom";

import { LoadingState } from "~/common/components";
import EmptyBox from "~/common/components/icons/EmptyBox";
import {
  useGetPatient,
  useGetPatientConsultationSummary,
} from "~/providers/hooks";
import { PatientParams } from "~/providers/utils";
import { PatientPreviewSections } from "./PatientPreviewSections";

export const PatientPreview = () => {
  const { patientId } = useParams<keyof PatientParams>() as PatientParams;

  const { data: patient } = useGetPatient(patientId);

  const { isLoading, screeningSummaryData, intakeSummaryData } =
    useGetPatientConsultationSummary(
      patient?.consultation?.id,
      patient?.consultation?.status,
      patient?.consultation?.has_intake_summary,
    );

  if (isLoading) {
    return <LoadingState />;
  }

  if (!screeningSummaryData) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-4 p-20">
        <EmptyBox />
        <p className="text-center text-base leading-5 text-gray-60">
          No information is available yet.
        </p>
      </div>
    );
  }

  return (
    <PatientPreviewSections
      screeningSummary={screeningSummaryData}
      intakeSummary={intakeSummaryData}
    />
  );
};
