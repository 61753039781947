import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { admitPatient } from "~/providers/api";

export const useAdmitPatient = (patientId: string, onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { mutate: admitPatientMutation, isPending } = useMutation({
    mutationFn: admitPatient,
    onError: () => toast.error("There was an issue admitting the patient."),
    onSuccess: async () => {
      toast.success("Patient admitted successfully!");
      await queryClient.invalidateQueries({
        queryKey: ["getPatient", patientId],
      });
      onSuccess?.();
    },
  });

  return { admitPatientMutation, isPending };
};
