import { TEAM_MEMBER_VALUES } from "~/common/constants";
import { PATIENT_CONSULTATION_STATUS } from "~/common/constants/consultation";
import {
  HandleRateSummaryProps,
  UseGetPatientInteractionsActionsProps,
} from "~/providers/utils/types/patientIntakePreviewTypes";
import { SUMMARY_TYPE } from "~/providers/utils/types/patientPreviewTypes";
import { useGetCurrentTeamMember } from "../auth";
import { useGetPatient } from "../useGetPatient";
import { useRateConsultationSummary } from "./useRateConsultationSummary";

export const useGetPatientInteractionsActions = ({
  patientId,
  screeningSummaryId,
  intakeSummaryId,
}: UseGetPatientInteractionsActionsProps) => {
  const { data: patient } = useGetPatient(patientId);
  const consultationId = patient?.consultation?.id;

  const { data: teamMember } = useGetCurrentTeamMember();

  const summaryType = intakeSummaryId
    ? SUMMARY_TYPE.intake
    : SUMMARY_TYPE.screening;

  const summaryId = intakeSummaryId ?? screeningSummaryId;

  const showRateButtons =
    (patient?.consultation?.status ===
      PATIENT_CONSULTATION_STATUS.screening_submitted ||
      patient?.consultation?.status ===
        PATIENT_CONSULTATION_STATUS.intake_submitted ||
      patient?.consultation?.status ===
        PATIENT_CONSULTATION_STATUS.appointment_scheduled) &&
    teamMember?.type === TEAM_MEMBER_VALUES.provider;

  const { rateSummaryMutation, isPending: isRatePending } =
    useRateConsultationSummary();

  const handleRateSummary = ({
    summaryType,
    isLike,
  }: HandleRateSummaryProps) => {
    rateSummaryMutation({
      summaryType,
      consultationId,
      summaryId,
      isLike,
    });
  };

  return {
    isRatePending,
    showRateButtons,
    summaryType,
    handleRateSummary,
  };
};
