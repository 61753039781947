import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";

import { CancelSubmitButtons } from "~/common/components";
import Modal from "~/common/components/Modal";
import TextArea from "~/common/components/ui/Textarea";
import { TEXT_MAX_LENGTH } from "~/common/constants";
import { CONSULTATION_ACTIONS } from "~/providers/constants";
import {
  useReOpenConsultation,
  useTerminateConsultation,
} from "~/providers/hooks";
import { internalNoteSchema, InternalNoteType } from "~/providers/utils";

interface ToggleTerminateConsultationModalProps {
  onClose: () => void;
  isOpenModal: boolean;
  patientId: string;
  consultationId: string;
  action?:
    | typeof CONSULTATION_ACTIONS.terminate
    | typeof CONSULTATION_ACTIONS.reopen;
}

export const ToggleTerminateConsultationModal = ({
  onClose,
  isOpenModal,
  patientId,
  consultationId,
  action,
}: ToggleTerminateConsultationModalProps) => {
  const handleCloseModal = () => {
    onClose();
    reset();
  };
  const { terminateConsultationMutation, isPending: pendingTerminate } =
    useTerminateConsultation({ patientId, onSuccess: handleCloseModal });

  const { reOpenConsultationMutation, isPending: pendingReOpen } =
    useReOpenConsultation({ patientId, onSuccess: handleCloseModal });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<InternalNoteType>({
    resolver: zodResolver(internalNoteSchema),
  });

  const onSubmit: SubmitHandler<InternalNoteType> = (data) =>
    action === CONSULTATION_ACTIONS.reopen
      ? reOpenConsultationMutation({ consultationId, data })
      : terminateConsultationMutation({ consultationId, data });

  const title =
    action === CONSULTATION_ACTIONS.terminate
      ? "Terminate consultation"
      : "Reopen consultation";

  const description =
    action === CONSULTATION_ACTIONS.terminate
      ? "The consultation is not complete. Are you sure you want to terminate this consultation?"
      : "Are you sure you want to reopen this consultation?";

  return (
    <Modal
      title={title}
      description={description}
      show={isOpenModal && !!action}
      onClose={handleCloseModal}
    >
      <form
        className="flex size-full flex-col gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextArea
          label="Internal note"
          placeholder=""
          rows={4}
          charactersCount={watch("internal_note")?.length}
          maxCharacters={TEXT_MAX_LENGTH}
          error={errors?.internal_note?.message}
          {...register("internal_note")}
        />
        <CancelSubmitButtons
          containerClassName="flex justify-end"
          cancelClassName="w-fit"
          submitClassName="w-fit"
          handleCancel={handleCloseModal}
          cancelButtonLabel="Cancel"
          submitButtonLabel={title}
          isSubmitting={pendingReOpen || pendingTerminate}
        />
      </form>
    </Modal>
  );
};
