import { useQuery } from "@tanstack/react-query";

import { getPatientScreeningConsultationSummary } from "~/providers/api";

export const useGetPatientScreeningConsultationSummary = (
  consultationId?: string,
  enabled?: boolean,
) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getPatientScreeningConsultationSummary(consultationId),
    queryKey: ["getPatientScreeningConsultationSummary", consultationId],
    enabled,
  });
  return { data, isLoading };
};
