import { useQuery } from "@tanstack/react-query";

import { getPaymentSettings } from "~/providers/api/teamMemberPaymentAndCarePlans";
import { useIsTeamMemberStaff } from "../../auth";

export const useGetPaymentSettings = () => {
  const { isStaff } = useIsTeamMemberStaff();

  const { data, isLoading } = useQuery({
    queryFn: () => getPaymentSettings(),
    queryKey: ["getPaymentSettings"],
    enabled: !isStaff,
  });
  return { data, isLoading };
};
