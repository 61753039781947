import { useSearchParams } from "react-router-dom";

import { ArrowIcon, TableHead, TableHeader } from "~/common/components";
import { DIRECTIONS, ORDER, ORDER_BY } from "~/common/constants";
import { PATIENT_FILTER_PARAMS } from "~/providers/constants";

export const PatientSearchTableHead = () => {
  const [params, setParams] = useSearchParams();

  const handleNameSortClick = () => {
    if (params.get(PATIENT_FILTER_PARAMS.order) === ORDER.descendant) {
      params.delete(PATIENT_FILTER_PARAMS.order);
      params.delete(PATIENT_FILTER_PARAMS.orderBy);
      setParams(params);
    } else {
      const newParams = new URLSearchParams(params);
      newParams.set(PATIENT_FILTER_PARAMS.order, ORDER.descendant);
      newParams.set(PATIENT_FILTER_PARAMS.orderBy, ORDER_BY.lastName);
      setParams(newParams);
    }
  };

  const direction =
    params.has(PATIENT_FILTER_PARAMS.order) &&
    params.get(PATIENT_FILTER_PARAMS.order) === ORDER.descendant
      ? DIRECTIONS.top
      : DIRECTIONS.down;

  return (
    <TableHead className="sticky top-0 overflow-hidden">
      <tr>
        <TableHeader scope="col" className="w-1/3 rounded-ss-2xl">
          <button
            className="flex items-center gap-2"
            onClick={handleNameSortClick}
          >
            Name
            <ArrowIcon direction={direction} />
          </button>
        </TableHeader>
        <TableHeader className="w-1/3">Consultation status</TableHeader>
        <TableHeader className="w-1/3 rounded-se-2xl">Engagement</TableHeader>
      </tr>
    </TableHead>
  );
};
