import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { PATIENT_STATUS } from "~/common/constants";
import { useHandleMultipleFilters } from "~/common/hooks";
import { Group } from "~/common/utils";
import { PATIENT_FILTER_PARAMS } from "../constants";
import {
  ENGAGEMENT_OPTIONS,
  engagementFilterOptions,
  EngagementTypes,
} from "../constants/patientSearchConstants";
import { hasConsultationAccessOnly } from "../utils";
import { useGetCurrentTeamMember } from "./queries";

export const NONE = "none" as const;

export const useHandlePatientSearchFilters = () => {
  const [params, setParams] = useSearchParams();
  const paramName = params.get(PATIENT_FILTER_PARAMS.name) ?? undefined;
  const [nameFilter, setNameFilter] = useState<string | undefined>(paramName);
  const { handleMultipleFilters } = useHandleMultipleFilters();
  const { data: teamMember } = useGetCurrentTeamMember();

  const userPermissions = teamMember?.user_permissions ?? [];
  const handlePatientSearchFilters = (filters: string[]) => {
    const engagementFiltersValues = filters.filter((filter) =>
      ENGAGEMENT_OPTIONS.includes(filter as EngagementTypes),
    );

    const engagementFilters = engagementFiltersValues.length
      ? engagementFiltersValues
      : [NONE];

    handleMultipleFilters(PATIENT_FILTER_PARAMS.engagements, engagementFilters);
  };

  const currentEngagementFilters = params.getAll(
    PATIENT_FILTER_PARAMS.engagements,
  );

  const handleNameFilterChange = (value?: string) => {
    setNameFilter(value);

    const newParams = new URLSearchParams(params);
    if (value) {
      newParams.set(PATIENT_FILTER_PARAMS.name, value);
      newParams.delete(PATIENT_FILTER_PARAMS.page);
    } else {
      newParams.delete(PATIENT_FILTER_PARAMS.name);
    }
    setParams(newParams);
  };

  const patientSearchFiltersOptions: Group[] = [
    {
      group: "Engagement",
      groupOptions: hasConsultationAccessOnly(userPermissions)
        ? engagementFilterOptions.filter(
            (option) => option.value !== PATIENT_STATUS.active,
          )
        : engagementFilterOptions,
    },
  ] as const;

  return {
    handlePatientSearchFilters,
    currentEngagementFilters,
    handleNameFilterChange,
    nameFilter,
    patientSearchFiltersOptions,
  };
};
