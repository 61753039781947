import {
  PATIENT_CONSULTATION_STATUS,
  PatientConsultationStatus,
} from "~/common/constants/consultation";
import { useGetPatientIntakeConsultationSummary } from "./useGetPatientIntakeConsultationSummary";
import { useGetPatientScreeningConsultationSummary } from "./useGetPatientScreeningConsultationSummary";

export const useGetPatientConsultationSummary = (
  consultationId?: string,
  consultationStatus?: PatientConsultationStatus,
  consultationHasIntakeSummary?: boolean,
) => {
  const isScreeningSummaryEnabled =
    consultationStatus !== PATIENT_CONSULTATION_STATUS.screening_not_started &&
    consultationStatus !== PATIENT_CONSULTATION_STATUS.screening_started;

  const {
    data: screeningSummaryData,
    isLoading: isLoadingScreeningSummaryData,
  } = useGetPatientScreeningConsultationSummary(
    consultationId,
    isScreeningSummaryEnabled,
  );

  const { data: intakeSummaryData, isLoading: isLoadingIntakeSummaryData } =
    useGetPatientIntakeConsultationSummary(
      consultationId,
      consultationHasIntakeSummary,
    );

  const isLoading = isLoadingScreeningSummaryData && isLoadingIntakeSummaryData;

  return { isLoading, screeningSummaryData, intakeSummaryData };
};
