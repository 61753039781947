import { useEffect, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { useSearchParams } from "react-router-dom";

import { SectionHeader } from "~/common/components";
import { MultiSelectGroup } from "~/common/components/ui/multiSelectGroup";
import { consultationStatusGroupOptions } from "~/common/constants/consultation";
import { useHandleMultipleFilters } from "~/common/hooks";
import { PATIENT_FILTER_PARAMS } from "~/providers/constants";
import { FilterResultSummary } from "../FilterResultSummary";
import SearchBar from "../SearchBar";

interface ConsultationRequestFiltersProps {
  isLoading: boolean;
  disableFilters: boolean;
  filteredPatientsCount?: number;
  totalPatients?: number;
}

export const ConsultationRequestFilters = ({
  isLoading,
  disableFilters,
  filteredPatientsCount,
  totalPatients,
}: ConsultationRequestFiltersProps) => {
  const [params, setParams] = useSearchParams();
  const paramName = params.get(PATIENT_FILTER_PARAMS.name) ?? undefined;
  const [patientFilter, setPatientFilter] = useState(paramName);

  const debouncedName = useDebounce(patientFilter, 600);

  useEffect(() => {
    const newParams = new URLSearchParams(params);
    if (debouncedName) {
      newParams.delete(PATIENT_FILTER_PARAMS.page);
      newParams.set(PATIENT_FILTER_PARAMS.name, debouncedName);
    } else {
      newParams.delete(PATIENT_FILTER_PARAMS.name);
    }
    setParams(newParams);
  }, [debouncedName, params, setParams]);

  const { handleMultipleFilters } = useHandleMultipleFilters();

  return (
    <div className="grid justify-between gap-4 md:flex">
      <SectionHeader className="flex flex-col text-xl font-semibold lg:text-3xl">
        Consultations
        <FilterResultSummary
          filteredPatientsCount={filteredPatientsCount}
          totalPatients={totalPatients}
        />
      </SectionHeader>
      <div className="flex flex-wrap gap-4 md:flex-nowrap">
        <SearchBar
          className="w-full"
          disabled={disableFilters}
          placeholder="Search by patient name"
          isLoading={isLoading}
          value={patientFilter}
          setValue={setPatientFilter}
        />
        <div className="flex w-full items-center justify-end md:w-fit">
          <MultiSelectGroup
            name="status"
            placeholder="Filter by"
            hasSelectAll={false}
            selectNoneLabel="Clear all"
            disabled={disableFilters}
            options={consultationStatusGroupOptions}
            value={params.getAll(PATIENT_FILTER_PARAMS.consultationStatus)}
            onChange={(filters) =>
              handleMultipleFilters(
                PATIENT_FILTER_PARAMS.consultationStatus,
                filters,
              )
            }
          />
        </div>
      </div>
    </div>
  );
};
