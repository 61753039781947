interface FilterResultSummaryProps {
  filteredPatientsCount?: number;
  totalPatients?: number;
}

export const FilterResultSummary = ({
  filteredPatientsCount,
  totalPatients,
}: FilterResultSummaryProps) => (
  <span className="block text-sm font-normal">
    Showing {filteredPatientsCount} of {totalPatients} patients
  </span>
);
