import { generatePath, useNavigate } from "react-router-dom";

import { TableData } from "~/common/components";
import { isPatientActive, Patient } from "~/common/utils";
import { PatientEngagementTag } from "~/providers/components";
import { ROUTES } from "~/providers/router";
import {
  formatFullName,
  getConsultationStatusText,
  getPatientEngagement,
} from "~/providers/utils";

interface PatientSearchRowTableDataProps {
  patient: Patient;
}

export const PatientSearchRowTableData = ({
  patient,
}: PatientSearchRowTableDataProps) => {
  const {
    id: patientId,
    name: firstName,
    last_name: lastName,
    status,
    consultation,
  } = patient;

  const consultationStatus = consultation?.status;

  const navigate = useNavigate();

  const tableRowRoute = isPatientActive(status)
    ? generatePath(ROUTES.activePatientActivityFeed, { patientId })
    : generatePath(ROUTES.consultationPreview, { patientId });

  const handleTableRowClick = () => navigate(tableRowRoute);

  const isTerminated = !!consultation?.terminated_at;

  return (
    <tr
      className="transition hover:cursor-pointer hover:bg-gray-50"
      onClick={handleTableRowClick}
    >
      <TableData>
        <div className="flex items-center gap-4 capitalize">
          {formatFullName(firstName, lastName)}
        </div>
      </TableData>
      <TableData className="first-letter:capitalize">
        {getConsultationStatusText(isTerminated, consultationStatus)}
      </TableData>
      <TableData>
        <PatientEngagementTag engagement={getPatientEngagement(patient)} />
      </TableData>
    </tr>
  );
};
