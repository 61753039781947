import { useQuery } from "@tanstack/react-query";

import { getConsultationReport } from "~/providers/api";

export const useGetConsultationReport = (
  consultationId?: string,
  enabled?: boolean,
) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getConsultationReport(consultationId),
    queryKey: ["getConsultationReport", consultationId],
    enabled,
  });

  return { data, isLoading };
};
