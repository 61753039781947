import { FieldErrors, UseFormRegister } from "react-hook-form";

import TextArea from "~/common/components/ui/Textarea";
import { getErrors } from "~/common/utils";
import {
  BasicPatientInformationInputs,
  MentalStatusReport,
} from "~/providers/components";
import {
  BasicPatientInformationSchemaType,
  CONSULTATION_REPORT_INPUT_TYPES,
  ConsultationReportSchemaType,
  MentalStatusExaminationReportSchemaType,
} from "~/providers/utils";
import { ConsultationReportInputType } from "~/providers/utils/types/consultation";

interface ConsultationReportInputsRendererProps {
  input: ConsultationReportInputType;
  isFormDisabled: boolean;
  register: UseFormRegister<ConsultationReportSchemaType>;
  errors: FieldErrors<ConsultationReportSchemaType>;
}

export const ConsultationReportInputsRenderer = ({
  input,
  isFormDisabled,
  register,
  errors,
}: ConsultationReportInputsRendererProps) => {
  const { type } = input;

  if (type === CONSULTATION_REPORT_INPUT_TYPES.textarea) {
    return (
      <TextArea
        disabled={isFormDisabled}
        rows={3}
        error={getErrors(input.fieldName, errors)}
        {...register(`${input.fieldName}`)}
      />
    );
  }

  if (type === CONSULTATION_REPORT_INPUT_TYPES.mental_status_examination) {
    return (
      <MentalStatusReport
        disableFields={isFormDisabled}
        register={(data: keyof MentalStatusExaminationReportSchemaType) =>
          register(`data.mental_status_examination.${data}`)
        }
        errors={errors}
      />
    );
  }

  if (type === CONSULTATION_REPORT_INPUT_TYPES.past_health_history) {
    return (
      <div className="flex w-full flex-col gap-3">
        <TextArea
          disabled={isFormDisabled}
          label="Psychiatric History"
          error={errors?.data?.past_psychiatric_history?.message}
          {...register("data.past_psychiatric_history")}
        />
        <TextArea
          disabled={isFormDisabled}
          label="Medical History"
          error={errors?.data?.past_medical_history?.message}
          {...register("data.past_medical_history")}
        />
      </div>
    );
  }

  if (type === CONSULTATION_REPORT_INPUT_TYPES.patient_information) {
    return (
      <BasicPatientInformationInputs
        register={(data: keyof BasicPatientInformationSchemaType) =>
          register(`data.basic_patient_information.${data}`)
        }
        errors={errors}
      />
    );
  }
};
