import { Outlet, useNavigate, useParams } from "react-router-dom";

import { SectionMenu } from "~/common/components";
import { ConsultationHeader } from "~/providers/components";
import { useGetPatient } from "~/providers/hooks";
import { getConsultationMenuOptions } from "~/providers/utils";

export const ConsultationLayout = () => {
  const navigate = useNavigate();

  const { patientId } = useParams();

  const { data: patient } = useGetPatient(patientId);

  if (!patientId || !patient) return null;

  const menuOptions = getConsultationMenuOptions(
    navigate,
    patientId,
    patient.status,
    patient.consultation?.has_intake_summary,
  );

  return (
    <>
      <div className="flex h-full w-full flex-col px-14 pt-5 text-sm">
        <ConsultationHeader patientConsultation={patient.consultation} />
        <div className="flex gap-2 pt-6">
          <SectionMenu menuOptions={menuOptions} />
        </div>

        <div className="h-full overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </>
  );
};
