import { useSearchParams } from "react-router-dom";

import { ORDER, ORDER_BY } from "~/common/constants";
import { useHandleMultipleFilters } from "~/common/hooks";
import { PATIENT_FILTER_PARAMS } from "~/providers/constants";
import { useGetPatients } from "~/providers/hooks";
import { PATIENT_LIST_STATUS_FILTERS } from "~/providers/utils";
import Pagination from "../Pagination";
import { RequestsLayout } from "../RequestsLayout";
import { ConsultationRequestFilters } from "./ConsultationRequestsFilter";
import { ConsultationRequestsTable } from "./ConsultationRequestsTable";

export const ConsultationRequests = () => {
  const { listPatientFilterApplied } = useHandleMultipleFilters();

  const [params] = useSearchParams({
    [PATIENT_FILTER_PARAMS.engagements]:
      PATIENT_LIST_STATUS_FILTERS.consultation,
    [PATIENT_FILTER_PARAMS.pageSize]: "11",
    [PATIENT_FILTER_PARAMS.orderBy]: ORDER_BY.completedDate,
    [PATIENT_FILTER_PARAMS.order]: ORDER.descendant,
  });

  const {
    data: consultationsRequests,
    isLoading,
    isFetched,
  } = useGetPatients(params);

  const {
    data: consultations,
    page,
    total_pages,
    total_items,
    filtered_count,
  } = { ...consultationsRequests };

  const hasConsultations = !!consultations?.length || listPatientFilterApplied;

  const emptyDataForSearchCriteria =
    !isLoading && isFetched && !consultations?.length;

  const showPagination =
    !!total_pages && total_pages > 1 && filtered_count && page;

  return (
    <RequestsLayout>
      <ConsultationRequestFilters
        isLoading={isLoading}
        disableFilters={!hasConsultations}
        filteredPatientsCount={filtered_count}
        totalPatients={total_items}
      />
      <div className="flex size-full flex-col items-end justify-between gap-3">
        {!isLoading && (
          <ConsultationRequestsTable
            consultations={consultations}
            hasConsultations={hasConsultations}
            emptyDataForSearchCriteria={emptyDataForSearchCriteria}
          />
        )}
        {showPagination && (
          <Pagination
            totalItems={filtered_count}
            currentPage={page}
            totalPages={total_pages}
          />
        )}
      </div>
    </RequestsLayout>
  );
};
