import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { SectionHeader } from "~/common/components";
import { PatientConsultationStatus } from "~/common/constants";
import { PatientStatus } from "~/common/utils";
import {
  CompleteSaveButtons,
  ConsultationInputLayout,
  ConsultationReportConfirmationModal,
  ConsultationReportInputsRenderer,
  DisabledReportMessage,
} from "~/providers/components";
import {
  useCompleteConsultationReport,
  useGetPatient,
  useUpdateConsultationReport,
} from "~/providers/hooks";
import {
  consultationReportInputs,
  consultationReportSchema,
  ConsultationReportSchemaType,
  getConsultationReportValues,
  PatientParams,
} from "~/providers/utils";
import { PatientConsultationReportData } from "~/providers/utils/types/consultation";

interface ConsultationReportFormProps {
  patientStatus: PatientStatus;
  patientConsultationStatus?: PatientConsultationStatus;
  data?: PatientConsultationReportData | null;
}

export const ConsultationReportForm = ({
  patientStatus,
  patientConsultationStatus,
  data,
}: ConsultationReportFormProps) => {
  const [saveChangesAutomatically, setSaveChangesAutomatically] =
    useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { patientId } = useParams<keyof PatientParams>() as PatientParams;
  const { data: patient } = useGetPatient(patientId);
  const isFormDisabled = !data;
  const consultationId = patient?.consultation?.id;

  const toggleConfirmationModal = () =>
    setShowConfirmationModal((prev) => !prev);

  const { updateConsultationReportMutation, isPending: pendingUpdateData } =
    useUpdateConsultationReport({
      consultationId,
      showSuccessMessage: !saveChangesAutomatically,
    });

  const { completeConsultationReportMutation, isPending: pendingComplete } =
    useCompleteConsultationReport(consultationId);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
    setValue,
  } = useForm<ConsultationReportSchemaType>({
    resolver: zodResolver(consultationReportSchema),
    values: {
      saveData: true,
      data: {
        ...getConsultationReportValues(data),
      },
    },
  });

  const onSubmit: SubmitHandler<ConsultationReportSchemaType> = (data) => {
    if (data.saveData) {
      setSaveChangesAutomatically(false);
      updateConsultationReportMutation(data.data);
    } else {
      toggleConfirmationModal();
    }
  };

  const handleSubmitConsultationReportCompletion = () => {
    const consultationReportData = getValues("data");
    completeConsultationReportMutation(consultationReportData);
  };

  useEffect(() => {
    return () => {
      if (!isDirty || !saveChangesAutomatically) return;
      const formValues = getValues();
      !isFormDisabled &&
        formValues.saveData &&
        updateConsultationReportMutation(formValues.data);
    };
  }, [isDirty, saveChangesAutomatically]);

  return (
    <div className="flex size-full flex-col gap-4 py-6">
      {isFormDisabled && (
        <DisabledReportMessage
          patientStatus={patientStatus}
          patientConsultationStatus={patientConsultationStatus}
        />
      )}
      <SectionHeader>Consultation report (H&P)</SectionHeader>
      <form
        className="flex size-full flex-col gap-6"
        onSubmit={handleSubmit(onSubmit)}
      >
        {consultationReportInputs.map((input) => {
          return (
            <ConsultationInputLayout label={input.label} key={input.label}>
              <ConsultationReportInputsRenderer
                input={input}
                isFormDisabled={isFormDisabled}
                register={register}
                errors={errors}
              />
            </ConsultationInputLayout>
          );
        })}
        <CompleteSaveButtons
          isFormDisabled={isFormDisabled}
          pendingUpdateData={pendingUpdateData}
          setValue={setValue}
          isDirty={isDirty}
        />
      </form>
      <ConsultationReportConfirmationModal
        isPending={pendingComplete}
        show={showConfirmationModal}
        onClose={toggleConfirmationModal}
        onSubmit={handleSubmitConsultationReportCompletion}
      />
    </div>
  );
};
