import React, { useEffect, useRef, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { Button, SectionHeader } from "~/common/components";
import TextArea from "~/common/components/ui/Textarea";
import { BUTTON_VARIANTS } from "~/common/constants";
import {
  AssessmentAndPlanConfirmationModal,
  ConsultationInputLayout,
  MentalStatusExamination,
  RadioOptionField,
} from "~/providers/components";
import {
  ADMISSION_RECOMMENDATION_VALUES,
  LARGE_TEXT_LENGTH,
  REFER_PATIENT,
  REFER_PATIENT_VALUES,
  TEXT_MAX_LENGTH,
} from "~/providers/constants";
import {
  useConsultationCompleteAndSign,
  useUpsertConsultationCompletionDetails,
} from "~/providers/hooks";
import {
  consultationCompletionDataSchema,
  ConsultationCompletionDataType,
  getConsultationCompletionValues,
  modifyConsultationCompletionData,
} from "~/providers/utils";
import {
  AssessmentAndPlanData,
  ConsultationCompletionDetail,
} from "~/providers/utils/types/consultation";

interface AssessmentAndPlanFormProps {
  patientId: string;
  completedData?: ConsultationCompletionDetail | null;
  isDisabledForm: boolean;
}

export const AssessmentAndPlanForm = ({
  patientId,
  completedData,
  isDisabledForm,
}: AssessmentAndPlanFormProps) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [saveChangesAutomatically, setSaveChangesAutomatically] =
    useState(true);
  const existingData = useRef<ConsultationCompletionDetail | null>();
  const isDirtyRef = useRef(false);
  existingData.current = completedData;

  const toggleConfirmationModal = () =>
    setShowConfirmationModal((prev) => !prev);

  const {
    consultationCompleteAndSignMutation,
    isPending: pendingCompleteAndSign,
  } = useConsultationCompleteAndSign(patientId, toggleConfirmationModal);

  const { upsertConsultationCompletionMutation, isPending: pendingSaveData } =
    useUpsertConsultationCompletionDetails({
      patientId,
      saveChangesAutomatically,
      setSaveChangesAutomatically,
    });

  const {
    register,
    handleSubmit,
    watch,
    control,
    resetField,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm<ConsultationCompletionDataType>({
    resolver: zodResolver(consultationCompletionDataSchema),
    values: getConsultationCompletionValues(completedData),
  });
  isDirtyRef.current = isDirty;
  const formValues = getValues();

  const onSubmit: SubmitHandler<ConsultationCompletionDataType> = (
    submittedData,
  ) =>
    submittedData.completeAndSign
      ? toggleConfirmationModal()
      : upsertConsultationCompletionMutation({
          existingData: existingData.current,
          data: modifyConsultationCompletionData(submittedData).data,
        });

  useEffect(() => {
    return () => {
      const unsavedData = getValues();
      if (!isDirtyRef.current || unsavedData.completeAndSign) return;
      upsertConsultationCompletionMutation({
        existingData: existingData.current,
        data: modifyConsultationCompletionData(unsavedData).data,
      });
    };
  }, []);

  const handleCompleteSaveData = (completeAndSign: boolean) => {
    setSaveChangesAutomatically(false);
    setValue("completeAndSign", completeAndSign);
  };

  return (
    <form
      className="flex size-full flex-col gap-6 py-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col gap-4">
        <SectionHeader>Assessment & Plan</SectionHeader>
        <ConsultationInputLayout
          label="Mental status examination"
          subtitle="A set of 9 multiple choice questions"
        >
          <MentalStatusExamination
            getValues={getValues}
            register={register}
            errors={errors}
            control={control}
            isDisabled={isDisabledForm}
          />
        </ConsultationInputLayout>
        <ConsultationInputLayout label="Observations and diagnostic considerations">
          <TextArea
            placeholder="Add your observations and diagnostic considerations"
            error={errors.data?.diagnostic_considerations?.message}
            rows={3}
            {...register("data.diagnostic_considerations")}
            charactersCount={watch("data.diagnostic_considerations")?.length}
            id="data.diagnostic_considerations"
            maxCharacters={LARGE_TEXT_LENGTH}
            disabled={isDisabledForm}
          />
        </ConsultationInputLayout>
        <ConsultationInputLayout label="Treatment plan">
          <TextArea
            placeholder="Add your Treatment plan"
            error={errors.data?.treatment_plan?.message}
            rows={3}
            {...register("data.treatment_plan")}
            maxCharacters={LARGE_TEXT_LENGTH}
            charactersCount={watch("data.treatment_plan")?.length}
            disabled={isDisabledForm}
          />
        </ConsultationInputLayout>
        <div className="flex w-full gap-2">
          <RadioOptionField
            label="Admission recommendation"
            options={ADMISSION_RECOMMENDATION_VALUES}
            register={register("data.admission_recommended")}
            error={errors.data?.admission_recommended?.message}
            isDisabled={isDisabledForm}
          />
          <RadioOptionField
            label="Referral"
            options={REFER_PATIENT_VALUES}
            register={register("data.refer_patient", {
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                e.target.value === REFER_PATIENT.no_referral_needed &&
                resetField("data.refer_note", { defaultValue: null }),
            })}
            error={errors.data?.refer_patient?.message}
            isDisabled={isDisabledForm}
          >
            <TextArea
              placeholder="Add a note for the referral"
              {...register("data.refer_note")}
              error={errors.data?.refer_note?.message}
              disabled={
                watch("data.refer_patient") !==
                  REFER_PATIENT.referral_provided || isDisabledForm
              }
              maxCharacters={TEXT_MAX_LENGTH}
              charactersCount={watch("data.refer_note")?.length}
            />
          </RadioOptionField>
        </div>
      </div>
      <div className="flex w-fit gap-2 self-end pb-3">
        <Button
          type="submit"
          className={twMerge("w-fit", pendingSaveData && "w-32 justify-center")}
          size="sm"
          variant={BUTTON_VARIANTS.outline_black}
          onClick={() => handleCompleteSaveData(false)}
          isLoading={pendingSaveData}
          disabled={!isDirty || isDisabledForm}
        >
          Save changes
        </Button>
        <Button
          type="submit"
          className="w-fit"
          size="sm"
          onClick={() => handleCompleteSaveData(true)}
          disabled={isDisabledForm}
        >
          Complete consultation
        </Button>
      </div>
      <AssessmentAndPlanConfirmationModal
        show={showConfirmationModal}
        onClose={toggleConfirmationModal}
        isPending={pendingCompleteAndSign}
        data={
          modifyConsultationCompletionData(formValues)
            .data as AssessmentAndPlanData
        }
        onSubmit={consultationCompleteAndSignMutation}
      />
    </form>
  );
};
