import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { completeAndSignConsultationCompletionDetails } from "~/providers/api/consultationDetails";
import { AssessmentAndPlanData } from "~/providers/utils/types/consultation";
import { useGetPatient } from "../useGetPatient";

export const useConsultationCompleteAndSign = (
  patientId: string,
  onSuccess: () => void,
) => {
  const queryClient = useQueryClient();

  const { data: patientData } = useGetPatient(patientId);
  const consultationId = patientData?.consultation?.id;

  const { mutate: consultationCompleteAndSignMutation, isPending } =
    useMutation({
      mutationFn: (consultationDetails: AssessmentAndPlanData) =>
        completeAndSignConsultationCompletionDetails({
          consultationId,
          consultationDetails,
        }),
      onSuccess: async () => {
        onSuccess();
        await queryClient.invalidateQueries({
          queryKey: ["getPatient", patientId],
        });
        await queryClient.invalidateQueries({
          queryKey: ["getConsultationCompletionDetail", consultationId],
        });
        toast.success("Consultation completed successfully!");
      },
      onError: () =>
        toast.error(
          "We shoot trouble completing the information, is the issue persists please reload the page!",
        ),
    });

  return { consultationCompleteAndSignMutation, isPending };
};
