import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import {
  CancelSubmitButtons,
  ErrorText,
  Typography,
} from "~/common/components";
import Modal from "~/common/components/Modal";
import Input from "~/common/components/ui/Input";
import { RADIO_BUTTON_VARIANTS } from "~/common/constants";
import RadioButtonOption from "~/patients/components/ui/RadioButtonOption";
import {
  useCreateConsultationDetails,
  useGetPaymentSettings,
} from "~/providers/hooks";
import {
  consultationDetailSchema,
  ConsultationDetailType,
  PRICE_TYPES,
} from "~/providers/utils";

interface ProceedToIntakeFormModalProps {
  handleCloseModal: () => void;
  isOpenModal: boolean;
  consultationId?: string;
}

export const ProceedToIntakeFormModal = ({
  handleCloseModal,
  isOpenModal,
  consultationId,
}: ProceedToIntakeFormModalProps) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<ConsultationDetailType>({
    resolver: zodResolver(consultationDetailSchema),
    defaultValues: { priceType: PRICE_TYPES.default },
  });

  const { data: paymentSettings } = useGetPaymentSettings();
  const priceFromBE = `${paymentSettings?.default_consultation_fee ?? 0}`;

  const onCloseModal = () => {
    handleCloseModal();
    setTimeout(() => {
      reset();
    }, 200);
  };

  const { createConsultationDetailsMutation, isPending } =
    useCreateConsultationDetails(onCloseModal);

  const isCustomPrice = watch("priceType") === PRICE_TYPES.custom;

  const onSubmit: SubmitHandler<ConsultationDetailType> = (data) => {
    const isCustomPrice = data.priceType === PRICE_TYPES.custom;

    createConsultationDetailsMutation({
      price: isCustomPrice ? Number(data.price) : Number(priceFromBE),
      consultationId,
    });
  };

  return (
    <Modal
      titleClassName="text-2xl font-medium w-2/3 text-gray-70"
      title="Proceed to intake"
      description="The patient will be prompted to pay the consultation fee in order to proceed to intake."
      show={isOpenModal}
      onClose={onCloseModal}
    >
      <div>
        <form
          key={`${isOpenModal}`}
          className="flex h-full w-full flex-col gap-3 text-gray-70"
          onSubmit={handleSubmit(onSubmit)}
        >
          <RadioButtonOption
            label="Default fee"
            variant={RADIO_BUTTON_VARIANTS.left_radio}
            value={PRICE_TYPES.default}
            isSelected={!isCustomPrice}
            containerClassName={twMerge(
              "flex gap-4",
              !isCustomPrice && "bg-primary-50",
            )}
            labelClassName="font-semibold"
            {...register("priceType")}
          >
            <Typography className="py-3">${priceFromBE}</Typography>
          </RadioButtonOption>
          <RadioButtonOption
            label="Custom fee"
            variant={RADIO_BUTTON_VARIANTS.left_radio}
            value={PRICE_TYPES.custom}
            isSelected={isCustomPrice}
            containerClassName={twMerge(
              "flex gap-4",
              isCustomPrice && "bg-primary-50",
            )}
            labelClassName="font-semibold"
            {...register("priceType")}
          >
            <div className="flex w-1/4 items-center gap-1">
              <Typography>$</Typography>
              <Input
                key="custom-price-input"
                id="custom-price-input"
                placeholder=""
                disabled={!isCustomPrice}
                {...register("price")}
                className="min-w-12"
              />
            </div>
          </RadioButtonOption>
          {"price" in errors && <ErrorText>{errors.price?.message}</ErrorText>}
          <div className="flex flex-col gap-3 self-end">
            <CancelSubmitButtons
              cancelClassName="text-sm w-auto text-gray-70 border-gray-70"
              handleCancel={onCloseModal}
              cancelButtonLabel="Cancel"
              isSubmitting={isPending}
              handleSubmit={handleSubmit(onSubmit)}
              submitButtonLabel="Send Invitation"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};
