import {
  ResponseQuestionTypes,
  SummaryFeedback,
  SummaryType,
} from "./patientPreviewTypes";

export const FUNCTIONAL_DOMAIN_KEY = {
  health: "health",
  relationships: "relationships",
  social_support: "social_support",
  family: "family",
  hope: "hope",
  money: "money",
  enjoyment: "enjoyment",
  work: "work",
  mood: "mood",
  post_partum: "post_partum",
} as const;

export type FunctionalDomainKeys =
  (typeof FUNCTIONAL_DOMAIN_KEY)[keyof typeof FUNCTIONAL_DOMAIN_KEY];

export interface SymptomProgression {
  symptom: string;
  progression: string;
}

interface SentenceCompletions {
  classification: string;
  sentences: string[];
}

export interface FunctionalDomain {
  direct_answers?: string;
  sentence_completions: SentenceCompletions;
  classification: string;
  narrative?: string[];
}

export interface FunctionalDomains {
  health: FunctionalDomain;
  relationships: FunctionalDomain;
  social_support: FunctionalDomain;
  family: FunctionalDomain;
  hope: FunctionalDomain;
  money: FunctionalDomain;
  enjoyment: FunctionalDomain;
  work: FunctionalDomain;
  mood: FunctionalDomain;
  post_partum?: FunctionalDomain;
}

export interface IntakeSummaryData {
  id: string;
  long_summary: string;
  short_summary: string;
  functional_domains: FunctionalDomains;
  symptom_progression: SymptomProgression[];
  treatment_recommendations: string;
}

export interface PatientIntakeConsultationSummary {
  id: string;
  consultation_id: string;
  feedbacks: SummaryFeedback[];
  type: ResponseQuestionTypes;
  data: IntakeSummaryData;
}

export interface RateSummaryProps {
  summaryId: string;
  consultationId?: string;
  summaryType: SummaryType;
  isLike: boolean | null;
}

export interface UseGetPatientInteractionsActionsProps {
  patientId: string;
  screeningSummaryId: string;
  intakeSummaryId?: string;
}

export interface HandleRateSummaryProps {
  summaryType: SummaryType;
  isLike: boolean | null;
}
