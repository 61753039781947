import { useLayoutEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { LoadingState } from "~/common/components";
import EmptyBox from "~/common/components/icons/EmptyBox";
import { isPatientActive } from "~/common/utils";
import RawResponsesSection from "~/providers/components/consultationRequests/RawResponsesSection";
import {
  useGetPatient,
  useGetPatientRawResponsesIntake,
  useGetPatientRawResponsesScreening,
} from "~/providers/hooks/queries";
import { useRawResponsesStore } from "~/providers/stores";
import { PatientParams } from "~/providers/utils";
import {
  patientCompletedIntake,
  patientCompletedScreening,
} from "~/providers/utils/patientStatusHistory";

export const PatientRawResponses = () => {
  const { patientId } = useParams<keyof PatientParams>() as PatientParams;
  const { data: patient } = useGetPatient(patientId);
  const patientIsActive = patient && isPatientActive(patient?.status);
  const consultationStatus = patient?.consultation?.status;

  const rawResponsesIntakeAvailable =
    patientCompletedIntake(consultationStatus) ?? patientIsActive;
  const rawResponsesScreeningAvailable =
    patientCompletedScreening(consultationStatus) ?? patientIsActive;

  const {
    data: rawResponsesScreening,
    isLoading: isLoadingScreeningResponses,
  } = useGetPatientRawResponsesScreening(
    patientId,
    rawResponsesScreeningAvailable,
  );
  const { data: rawResponsesIntake, isLoading: isLoadingIntakeResponses } =
    useGetPatientRawResponsesIntake(patientId, rawResponsesIntakeAvailable);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const {
    setScrollPosition,
    scrollPosition,
    patientId: storedResponsePatientId,
  } = useRawResponsesStore();

  useLayoutEffect(() => {
    if (scrollContainerRef.current && patientId === storedResponsePatientId) {
      scrollContainerRef.current.scrollTop = scrollPosition;
    }
    return () => {
      if (scrollContainerRef.current) {
        setScrollPosition(scrollContainerRef.current.scrollTop);
      }
    };
  }, [patientId, storedResponsePatientId]);

  if (isLoadingIntakeResponses || isLoadingScreeningResponses)
    return <LoadingState className="p-20" />;

  if (!rawResponsesScreening && !rawResponsesIntake)
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-4 p-20">
        <EmptyBox />
        <p className="text-center text-base leading-5 text-gray-60">
          No answers are available yet.
        </p>
      </div>
    );

  return (
    <div
      ref={scrollContainerRef}
      className="flex h-full w-full flex-col gap-8 overflow-y-auto pb-4 pt-6"
    >
      {rawResponsesScreening && (
        <RawResponsesSection
          rawResponses={rawResponsesScreening}
          patient={patient}
        />
      )}
      {rawResponsesIntake && (
        <RawResponsesSection
          rawResponses={rawResponsesIntake}
          patient={patient}
        />
      )}
    </div>
  );
};
