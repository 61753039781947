import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { CancelSubmitButtons, LoadingState } from "~/common/components";
import Modal from "~/common/components/Modal";
import Select from "~/common/components/ui/select/Select";
import TextArea from "~/common/components/ui/Textarea";
import { TEXT_MAX_LENGTH } from "~/common/constants";
import { useAdmitPatient, useListCarePlans } from "~/providers/hooks";
import {
  AdmitPatientFormValues,
  admitPatientSchema,
  generateCarePlanOptions,
  PatientData,
} from "~/providers/utils";

interface AdmitPatientModalProps {
  show: boolean;
  onClose: () => void;
  patient: PatientData;
}

export const AdmitPatientModal = ({
  onClose,
  show,
  patient,
}: AdmitPatientModalProps) => {
  const { data: carePlansList, isLoading: isLoadingCarePlans } =
    useListCarePlans({
      withPatients: false,
    });

  const defaultCarePlan = carePlansList?.find((carePlan) => carePlan.default);
  const {
    control,
    reset,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<AdmitPatientFormValues>({
    resolver: zodResolver(admitPatientSchema),
    values: {
      provider_care_plan_id: defaultCarePlan?.id ?? "",
      internal_note: "",
    },
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const { id: patientId, consultation } = patient;
  const consultationId = consultation?.id;
  const { admitPatientMutation, isPending } = useAdmitPatient(
    patientId,
    handleClose,
  );

  const onSubmit = (admitPatientData: AdmitPatientFormValues) =>
    consultationId &&
    admitPatientMutation({ consultationId, admitPatientData });

  return (
    <Modal title="Admit patient" show={show} onClose={handleClose}>
      {isLoadingCarePlans ? (
        <LoadingState />
      ) : (
        <form
          className="flex size-full flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Select
            id="provider_care_plan_id"
            error={errors.provider_care_plan_id?.message}
            placeholder="Select plan"
            label="Care plan"
            control={control}
            options={generateCarePlanOptions(carePlansList ?? [])}
          />
          <TextArea
            label="Internal note (optional)"
            placeholder=""
            rows={4}
            charactersCount={watch("internal_note")?.length}
            maxCharacters={TEXT_MAX_LENGTH}
            error={errors.internal_note?.message}
            {...register("internal_note")}
          />
          <CancelSubmitButtons
            containerClassName="flex justify-end"
            cancelClassName="w-fit"
            submitClassName="w-fit"
            handleCancel={handleClose}
            cancelButtonLabel="Cancel"
            submitButtonLabel="Admit"
            isSubmitting={isPending}
          />
        </form>
      )}
    </Modal>
  );
};
