import { replaceUnderscoresUpperCase } from "~/common/utils";
import { formatDate } from "~/providers/utils";
import { PatientConsultationReportData } from "~/providers/utils/types/consultation";

export const getConsultationReportValues = (
  data?: PatientConsultationReportData | null,
) => ({
  basic_patient_information: {
    date_of_birth: data?.basic_patient_information.date_of_birth
      ? formatDate(data.basic_patient_information.date_of_birth)
      : "",
    occupation: data?.basic_patient_information.occupation ?? "",
    marital_status: replaceUnderscoresUpperCase(
      data?.basic_patient_information.marital_status ?? "",
    ),
    gender: replaceUnderscoresUpperCase(
      data?.basic_patient_information.gender ?? "",
    ),
  },
  chief_complaint: data?.chief_complaint ?? "",
  history_of_present_illness: data?.history_of_present_illness ?? "",
  past_psychiatric_history: data?.past_psychiatric_history ?? "",
  past_medical_history: data?.past_medical_history ?? "",
  medications: data?.medications ?? "",
  allergies: data?.allergies ?? "",
  substances: data?.substances ?? "",
  past_personal_history: data?.past_personal_history ?? "",
  education_history: data?.education_history ?? "",
  family_history: data?.family_history ?? "",
  psychiatric_review: data?.psychiatric_review ?? "",
  mental_status_examination: {
    appearance: replaceUnderscoresUpperCase(
      data?.mental_status_examination.appearance ?? "",
    ),
    behavior: replaceUnderscoresUpperCase(
      data?.mental_status_examination.behavior ?? "",
    ),
    speech: replaceUnderscoresUpperCase(
      data?.mental_status_examination.speech ?? "",
    ),
    mood: replaceUnderscoresUpperCase(
      data?.mental_status_examination.mood ?? "",
    ),
    affect: replaceUnderscoresUpperCase(
      data?.mental_status_examination.affect ?? "",
    ),
    thought_process: replaceUnderscoresUpperCase(
      data?.mental_status_examination.thought_process ?? "",
    ),
    thought_content: replaceUnderscoresUpperCase(
      data?.mental_status_examination.thought_content ?? "",
    ),
    attention_and_concentration: replaceUnderscoresUpperCase(
      data?.mental_status_examination.attention_and_concentration ?? "",
    ),
    judgment_and_insight: replaceUnderscoresUpperCase(
      data?.mental_status_examination.judgment_and_insight ?? "",
    ),
  },
  plan_of_care: data?.plan_of_care ?? "",
  assessment: data?.assessment ?? "",
});
