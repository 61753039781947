import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

import { CancelSubmitButtons } from "~/common/components";
import Modal from "~/common/components/Modal";
import TextArea from "~/common/components/ui/Textarea";
import { TEXT_MAX_LENGTH } from "~/providers/constants";
import { useCreateActivityFeedNote } from "~/providers/hooks";
import { addActivityFeedNoteSchema } from "~/providers/utils";

export type AddActivityNoteFormValues = z.infer<
  typeof addActivityFeedNoteSchema
>;
interface NewNotaModalProps {
  show: boolean;
  onClose: () => void;
  patientId: string;
}

export const NewNoteModal = ({
  onClose,
  show,
  patientId,
}: NewNotaModalProps) => {
  const {
    watch,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<AddActivityNoteFormValues>({
    resolver: zodResolver(addActivityFeedNoteSchema),
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const { createActivityFeedNoteMutation, isPending } =
    useCreateActivityFeedNote(patientId, handleClose);

  const onSubmit: SubmitHandler<AddActivityNoteFormValues> = (data) => {
    createActivityFeedNoteMutation(data.note);
  };

  return (
    <Modal
      className="h-fit w-96"
      title="Note"
      show={show}
      onClose={handleClose}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col items-end gap-4"
      >
        <TextArea
          rows={4}
          {...register("note")}
          error={errors.note?.message}
          charactersCount={watch("note")?.length}
          id="note"
          maxCharacters={TEXT_MAX_LENGTH}
        />
        <CancelSubmitButtons
          containerClassName="flex justify-end"
          cancelClassName="w-fit"
          submitClassName="w-fit"
          submitButtonLabel="Save note"
          handleCancel={handleClose}
          isSubmitting={isPending}
        />
      </form>
    </Modal>
  );
};
