import { generatePath, Link, useParams } from "react-router-dom";

import { SectionHeader } from "~/common/components";
import { SubmittedConsultationReportDataRenderer } from "~/providers/components";
import { ROUTES } from "~/providers/router";
import { PatientParams } from "~/providers/utils";
import { PatientConsultationReportData } from "~/providers/utils/types/consultation";

interface SubmittedConsultationReportProps {
  data: PatientConsultationReportData;
}

export const SubmittedConsultationReport = ({
  data,
}: SubmittedConsultationReportProps) => {
  const { patientId } = useParams<keyof PatientParams>() as PatientParams;

  return (
    <div className="flex flex-col gap-4 py-6">
      <div className="flex w-full justify-between">
        <SectionHeader>Consultation report (H&P)</SectionHeader>
        <Link
          className="flex w-fit items-center justify-center gap-2 rounded-md border border-black bg-neutral-25 p-2 font-medium text-black hover:bg-slate-100 focus:bg-slate-50 focus:outline-none focus:ring-offset-0"
          to={generatePath(ROUTES.exportConsultationReport, {
            patientId,
          })}
          target="_blank"
        >
          Open printable view
        </Link>
      </div>
      <div
        id="printable-content"
        className="print-container flex flex-col gap-3"
      >
        <SubmittedConsultationReportDataRenderer data={data} />
      </div>
    </div>
  );
};
