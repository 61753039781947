import { AdmitPatientFormValues, InternalNoteType } from "~/providers/utils";

export interface ArchivePatientParams {
  patientId: string;
  internalNote?: InternalNoteType;
}

export interface AdmitPatientDataProps {
  consultationId: string;
  admitPatientData: AdmitPatientFormValues;
}

export const PATIENT_ACTIONS = {
  archive: "archive",
  admit: "admit",
  new_note: "new_note",
} as const;

export type PatientActions =
  (typeof PATIENT_ACTIONS)[keyof typeof PATIENT_ACTIONS];
