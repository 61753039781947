import {
  PATIENT_CONSULTATION_STATUS,
  PATIENT_SCRIPT_STATUS,
} from "~/common/constants";
import {
  Address,
  PatientConsultation,
  PatientMedicationScriptsStatus,
  Script,
} from "~/common/types";
import { PatientStatus, TeamMember } from "~/common/utils";
import { PatientMedicationScript, PronounsOptions } from "~/patients/utils";
import {
  ENGAGEMENT_TYPES,
  NoteType,
  PATIENT_FILTER_PARAMS,
  SCRIPT_FILTERS,
} from "~/providers/constants";

export interface EmergencyContact {
  name: string;
  last_name?: string;
  relationship: string;
  email: string;
  phone_number: string;
}

export interface Note {
  content?: string;
  title: string;
  id: string;
}
export interface PatientData {
  email: string;
  birthday: string | null;
  name: string;
  last_name: string;
  id: string;
  pronouns: PronounsOptions;
  provider: TeamMember;
  phone_number: string;
  email_verified_at?: string;
  addresses: Address[];
  emergency_contacts: EmergencyContact[];
  id_photo_path?: string;
  note: Note | null;
  status: PatientStatus;
  archived_at?: string;
  consultation?: PatientConsultation;
}

export interface PatientNoteData {
  id: string;
  note?: Note;
  note_id: string;
  patient_id: string;
  type: NoteType;
}

export interface ProviderMedication extends PatientMedicationScript {
  scripts_statuses?: PatientMedicationScriptsStatus[];
}
export interface ProviderScriptDetails
  extends Omit<Script, "patient_medication"> {
  patient_medication: ProviderMedication;
}

export const PATIENTS_FILTERS = {
  all: "all",
  unreviewed: "unreviewed",
  reviewed: "reviewed",
} as const;

export const PATIENT_LIST_STATUS_FILTERS = {
  consultation: "consultation",
  active: "active",
  all: "all",
} as const;

export const defaultHomeParams = {
  [PATIENT_FILTER_PARAMS.engagements]: [PATIENT_LIST_STATUS_FILTERS.active],
  [PATIENT_FILTER_PARAMS.filter]: [PATIENTS_FILTERS.unreviewed],
};

export const defaultConsultationParams = {
  [PATIENT_FILTER_PARAMS.consultationStatus]: [
    PATIENT_CONSULTATION_STATUS.screening_not_started,
    PATIENT_CONSULTATION_STATUS.intake_not_started,
    PATIENT_CONSULTATION_STATUS.screening_started,
    PATIENT_CONSULTATION_STATUS.intake_started,
    PATIENT_CONSULTATION_STATUS.screening_submitted,
    PATIENT_CONSULTATION_STATUS.intake_submitted,
    PATIENT_CONSULTATION_STATUS.closed_after_screening,
    PATIENT_CONSULTATION_STATUS.appointment_scheduled,
    PATIENT_CONSULTATION_STATUS.completed,
  ],
};

export const defaultPrescriptionRequestParams = {
  [SCRIPT_FILTERS.status]: [
    PATIENT_SCRIPT_STATUS.pending,
    PATIENT_SCRIPT_STATUS.canceled,
    PATIENT_SCRIPT_STATUS.sent_to_pharmacy,
  ],
};

export const defaultPatientSearchParams = {
  [PATIENT_FILTER_PARAMS.engagements]: [
    ENGAGEMENT_TYPES.active,
    ENGAGEMENT_TYPES.inactive,
    ENGAGEMENT_TYPES.consultation,
  ],
};

export const DESCRIPTIVE_TAG_VARIANTS = {
  script_paused: "script_paused",
  simple: "simple",
} as const;

export type DescriptiveTagVariant =
  (typeof DESCRIPTIVE_TAG_VARIANTS)[keyof typeof DESCRIPTIVE_TAG_VARIANTS];

export type PatientsFilters = keyof typeof PATIENTS_FILTERS;

export interface PaymentSettings {
  provider_id: string;
  secret_key: string;
  publishable_key: string;
  default_consultation_fee: number;
}

export interface ConsultationDetails {
  id: string;
  provider_id: string;
  price: number;
  currency: string;
}

export interface PatientParams {
  patientId: string;
}
