import { useQuery } from "@tanstack/react-query";

import { DEFAULT_PAGE_SIZE } from "~/common/constants";
import { getScriptsList } from "~/providers/api/scriptsService";
import { PATIENT_FILTER_PARAMS } from "~/providers/constants";

export const useGetScriptsList = (
  params: URLSearchParams,
  enabled?: boolean,
) => {
  !params.has(PATIENT_FILTER_PARAMS.pageSize) &&
    params.append(PATIENT_FILTER_PARAMS.pageSize, DEFAULT_PAGE_SIZE);

  const { data, isLoading, isFetched } = useQuery({
    queryFn: () => getScriptsList(params),
    queryKey: ["getScriptsList", params.toString(), params],
    enabled,
  });
  return { data, isLoading, isFetched };
};
