import { SideBarContent, SidebarLayout } from "~/common/components";
import { joinParams } from "~/common/utils/generateQueryString";
import { Item } from "~/common/utils/types/sideBarTypes";
import {
  useGetCurrentTeamMember,
  useGetHomeRoute,
  useSideBarItems,
} from "~/providers/hooks";
import { useUiStore } from "~/providers/stores/useUiStore";
import {
  defaultConsultationParams,
  defaultHomeParams,
  hasGeneralAccess,
} from "~/providers/utils";
import { AuthenticatedTeamMember } from "./AuthenticatedTeamMember";

const TeamMemberSideBar = () => {
  const { homeRoute } = useGetHomeRoute();
  const { setSideBarOpen, sideBarOpen } = useUiStore();
  const { menuItems, bottomMenuItems } = useSideBarItems();

  const handleItemChange = (item: Item) => item.onClick();

  const { data: teamMember } = useGetCurrentTeamMember();

  const generalAccess = hasGeneralAccess(teamMember?.user_permissions);

  const params = generalAccess
    ? defaultHomeParams
    : joinParams(defaultConsultationParams);

  const handleOnClose = () => setSideBarOpen(false);

  return (
    <SidebarLayout isOpen={sideBarOpen} onClose={handleOnClose}>
      <SideBarContent
        menuItems={menuItems}
        bottomMenuItems={bottomMenuItems}
        handleItemChange={handleItemChange}
        onClose={handleOnClose}
        homePath={{
          pathname: homeRoute,
          search: new URLSearchParams(params).toString(),
        }}
      >
        <AuthenticatedTeamMember />
      </SideBarContent>
    </SidebarLayout>
  );
};

export default TeamMemberSideBar;
