import React, { ReactNode } from "react";

import Modal from "~/common/components/Modal";

interface OptionType {
  id: number;
  content: ReactNode;
}

interface PatientPreviewInfoModalProps {
  show: boolean;
  onClose: () => void;
  title: string;
  description: ReactNode;
  options: OptionType[];
  bottomDescription: ReactNode;
}

export const PatientPreviewInfoModal = ({
  show,
  onClose,
  title,
  description,
  options,
  bottomDescription,
}: PatientPreviewInfoModalProps) => (
  <Modal
    className="h-fit w-96"
    titleClassName="text-xl font-semibold w-2/3 text-gray-70"
    title={title}
    show={show}
    onClose={onClose}
  >
    <div className="flex flex-col gap-4 text-gray-700">
      {description}
      <ul className="flex list-disc flex-col gap-4 pl-8 text-sm">
        {options.map(({ id, content }) => (
          <li key={id}>{content}</li>
        ))}
      </ul>
      {bottomDescription}
    </div>
  </Modal>
);
