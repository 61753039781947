import { api } from "~/providers/api";
import { getTeamMemberConsultationBaseUrl } from "../utils";
import {
  PatientIntakeConsultationSummary,
  RateSummaryProps,
} from "../utils/types/patientIntakePreviewTypes";
import {
  PatientScreeningConsultationSummary,
  SummaryFeedback,
} from "../utils/types/patientPreviewTypes";

export const getPatientScreeningConsultationSummary = async (
  consultationId?: string,
) => {
  const { data } = await api.get<PatientScreeningConsultationSummary>(
    `${getTeamMemberConsultationBaseUrl(consultationId)}/summaries/screening`,
  );
  return data;
};

export const getPatientIntakeConsultationSummary = async (
  consultationId?: string,
) => {
  const { data } = await api.get<PatientIntakeConsultationSummary>(
    `${getTeamMemberConsultationBaseUrl(consultationId)}/summaries/intake`,
  );
  return data;
};

export const rateSummary = async ({
  summaryId,
  consultationId,
  summaryType,
  isLike,
}: RateSummaryProps) => {
  const { data } = await api.post<SummaryFeedback>(
    `${getTeamMemberConsultationBaseUrl(
      consultationId,
    )}/summaries/${summaryId}/rate`,
    {
      is_like: isLike,
      consultation_type: summaryType,
    },
  );
  return data;
};
