import { generatePath, matchPath, NavigateFunction } from "react-router-dom";

import { StarsIcon } from "~/common/components";
import { SectionMenuOption } from "~/common/types";
import { isPatientActive, PatientStatus } from "~/common/utils";
import { ROUTES } from "../router";
import { getAssessmentAndPlanRoute } from "./getUrls";

export const getConsultationMenuOptions = (
  navigate: NavigateFunction,
  patientId: string,
  patientStatus: PatientStatus,
  patientConsultationHasIntakeSummary?: boolean,
): SectionMenuOption[] => {
  const actualPath = window.location.pathname;
  const isActivePatient = isPatientActive(patientStatus);

  const showConsultationCompletionTabs =
    Boolean(patientConsultationHasIntakeSummary) || isActivePatient;

  const previewRoute = isActivePatient
    ? ROUTES.patientPreview
    : ROUTES.consultationPreview;

  const rawResponsesRoute = isActivePatient
    ? ROUTES.patientResponses
    : ROUTES.consultationResponses;

  const completeRoute = getAssessmentAndPlanRoute(patientStatus);
  const consultationReportRoute = isActivePatient
    ? ROUTES.ongoingPatientConsultationReport
    : ROUTES.leadPatientConsultationReport;
  return [
    {
      label: "Preview",
      onClick: () =>
        navigate(
          generatePath(previewRoute, {
            patientId,
          }),
        ),
      current: !!matchPath(previewRoute, actualPath),
      enable: true,
      icon: <StarsIcon />,
    },
    {
      label: "Raw responses",
      onClick: () =>
        navigate(
          generatePath(rawResponsesRoute, {
            patientId,
          }),
        ),
      current: !!matchPath(rawResponsesRoute, actualPath),
      enable: true,
    },
    {
      label: "Assessment & plan",
      onClick: () =>
        navigate(
          generatePath(completeRoute, {
            patientId,
          }),
        ),
      current: !!matchPath(completeRoute, actualPath),
      enable: showConsultationCompletionTabs,
    },
    {
      label: "Consultation report (H&P)",
      onClick: () =>
        navigate(
          generatePath(consultationReportRoute, {
            patientId,
          }),
        ),
      current: !!matchPath(consultationReportRoute, actualPath),
      enable: showConsultationCompletionTabs,
    },
  ];
};
