import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

import { LoadingState } from "~/common/components";
import Modal from "~/common/components/Modal";
import { CHECK_IN_TYPES } from "~/common/utils/types/checkInTypes";
import { AddOrderForm } from "../components/patientProfile/orderPanel/AddOrderForm";
import { useCheckinSubmit } from "../hooks/queries/useCheckinSubmit";
import { useGetBasicCheckinsList } from "../hooks/queries/useGetBasicCheckinsList";
import { addOrderSchema } from "../utils/addOrderSchema";

interface AddOrderModalProps {
  show: boolean;
  onClose: () => void;
  patientId: string;
}
export type AddOrderFormValues = z.infer<typeof addOrderSchema>;

export const AddOrderModal = ({
  show,
  onClose,
  patientId,
}: AddOrderModalProps) => {
  const { data: checkInsList, isLoading } = useGetBasicCheckinsList(
    CHECK_IN_TYPES.all,
    show,
    "side_effects",
    patientId,
  );

  const {
    control,
    handleSubmit,
    watch,
    reset,
    resetField,
    register,
    getValues,
    formState: { errors },
  } = useForm<AddOrderFormValues>({
    resolver: zodResolver(addOrderSchema),
  });

  const handleCloseModal = () => {
    onClose();
    reset({ orderType: undefined });
  };

  const { onSubmitCheckin, isPending } = useCheckinSubmit({
    handleCloseModal,
    patientId,
  });

  const onSubmit: SubmitHandler<AddOrderFormValues> = (
    data: AddOrderFormValues,
  ) => {
    checkInsList && onSubmitCheckin(data, checkInsList);
  };

  return (
    <Modal
      show={show}
      onClose={handleCloseModal}
      title="Add new order"
      closeOnClickOutside={false}
      className="max-h-full duration-300 lg:w-2/5"
    >
      {isLoading || !checkInsList ? (
        <LoadingState />
      ) : (
        <AddOrderForm
          patientId={patientId}
          resetField={resetField}
          errors={errors}
          watch={watch}
          reset={reset}
          control={control}
          register={register}
          handleCancel={handleCloseModal}
          handleSubmit={handleSubmit(onSubmit)}
          checkInsList={checkInsList}
          getValues={getValues}
          isPending={isPending}
        />
      )}
    </Modal>
  );
};
