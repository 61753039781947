import { ConsultationReportInputType } from "~/providers/utils/types/consultation";
import { CONSULTATION_REPORT_INPUT_TYPES } from "./inputTypes";

export const consultationReportInputs: ConsultationReportInputType[] = [
  {
    label: "Basic Patient Information",
    type: CONSULTATION_REPORT_INPUT_TYPES.patient_information,
  },
  {
    label: "Chief Complaint",
    type: CONSULTATION_REPORT_INPUT_TYPES.textarea,
    fieldName: "data.chief_complaint",
  },
  {
    label: "History of Present Illness",
    type: CONSULTATION_REPORT_INPUT_TYPES.textarea,
    fieldName: "data.history_of_present_illness",
  },
  {
    label: "Past Health History",
    type: CONSULTATION_REPORT_INPUT_TYPES.past_health_history,
  },
  {
    label: "Medications",
    type: CONSULTATION_REPORT_INPUT_TYPES.textarea,
    fieldName: "data.medications",
  },
  {
    label: "Substance history",
    type: CONSULTATION_REPORT_INPUT_TYPES.textarea,
    fieldName: "data.substances",
  },
  {
    label: "Allergies",
    type: CONSULTATION_REPORT_INPUT_TYPES.textarea,
    fieldName: "data.allergies",
  },
  {
    label: "Past personal history",
    type: CONSULTATION_REPORT_INPUT_TYPES.textarea,
    fieldName: "data.past_personal_history",
  },
  {
    label: "Educational history",
    type: CONSULTATION_REPORT_INPUT_TYPES.textarea,
    fieldName: "data.education_history",
  },
  {
    label: "Family history",
    type: CONSULTATION_REPORT_INPUT_TYPES.textarea,
    fieldName: "data.family_history",
  },
  {
    label: "Psychiatric review of symptoms",
    type: CONSULTATION_REPORT_INPUT_TYPES.textarea,
    fieldName: "data.psychiatric_review",
  },
  {
    label: "Mental status examination",
    type: CONSULTATION_REPORT_INPUT_TYPES.mental_status_examination,
  },
  {
    label: "Assessment",
    type: CONSULTATION_REPORT_INPUT_TYPES.textarea,
    fieldName: "data.assessment",
  },
  {
    label: "Plan of care",
    type: CONSULTATION_REPORT_INPUT_TYPES.textarea,
    fieldName: "data.plan_of_care",
  },
];
