import { twMerge } from "tailwind-merge";

import { SectionHeader, TopBar } from "~/common/components";
import {
  PATIENT_FILTER_PARAMS,
  PATIENT_FILTER_PARAMS_VALUES,
} from "~/providers/constants/patients";
import FilterPatientsButton from "../components/FilterPatientsButton";
import Pagination from "../components/Pagination";
import PatientsTable from "../components/PatientsTable";
import SearchBar from "../components/SearchBar";
import { useTeamMemberHome } from "../hooks";
import { useUiStore } from "../stores/useUiStore";
import { PATIENTS_FILTERS, PatientsFilters } from "../utils/types";

export const Home = () => {
  const { setSideBarOpen } = useUiStore();
  const {
    setNameFilter,
    onFilterChange,
    patients,
    isLoading,
    totalPatients,
    nameFilter,
    params,
  } = useTeamMemberHome();

  const noFilterApplied = !PATIENT_FILTER_PARAMS_VALUES.some((filter) =>
    params.has(filter),
  );
  const isDisabled = noFilterApplied && !patients?.data.length;

  const noPatients = !patients?.data.length;

  return (
    <div
      className={twMerge(
        "flex h-full w-full flex-col gap-4 px-6 py-8 text-sm md:px-14",
        noPatients && "h-screen-calculated",
      )}
    >
      <div className="grid justify-between gap-4 md:flex">
        <div className="flex items-center gap-4">
          <TopBar handleOpenSideBar={() => setSideBarOpen(true)} />
          <div className="flex flex-col">
            <SectionHeader className="text-xl font-semibold xl:text-3xl">
              Patients
            </SectionHeader>
            <span>
              Showing {patients?.filtered_count} of {totalPatients} patients
            </span>
          </div>
        </div>

        <div className="flex flex-wrap gap-4 md:flex-nowrap">
          <SearchBar
            className="w-full"
            disabled={isDisabled}
            isLoading={isLoading}
            value={nameFilter}
            placeholder="Search by patient name"
            setValue={setNameFilter}
          />
          <div className="flex w-full shrink-0 items-center justify-end md:w-fit">
            <FilterPatientsButton
              filter={
                (params.get(PATIENT_FILTER_PARAMS.filter) as PatientsFilters) ??
                PATIENTS_FILTERS.all
              }
              onFilterChanged={onFilterChange}
            />
          </div>
        </div>
      </div>
      {patients?.data && (
        <div className="flex h-full flex-col gap-6 pb-7">
          <PatientsTable patients={patients?.data} isLoading={isLoading} />
          {patients && patients.total_pages > 1 && (
            <div className="flex w-full justify-end">
              <Pagination
                totalItems={patients.filtered_count}
                currentPage={patients.page}
                totalPages={patients.total_pages}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
