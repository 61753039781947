import { SectionHeader } from "~/common/components";
import {
  CompletedConsultationFieldLayout,
  CompletedMentalStatusExamination,
} from "~/providers/components";
import { REFER_PATIENT } from "~/providers/constants";
import { ConsultationCompletionDetail } from "~/providers/utils/types/consultation";

interface ConsultationCompletionDetailsProps {
  details: ConsultationCompletionDetail;
}

export const ConsultationCompletionDetails = ({
  details,
}: ConsultationCompletionDetailsProps) => {
  const {
    id,
    diagnostic_considerations,
    treatment_plan,
    refer_patient,
    refer_note,
    admission_recommended,
  } = details;

  const admissionDecisionText = admission_recommended
    ? "Admission recommended"
    : "Patient admission not recommended";

  const referralDecision =
    refer_patient === REFER_PATIENT.referral_provided
      ? "Referral provided"
      : "No referral needed";

  return (
    <div className="flex flex-col gap-4 py-6" id={id}>
      <SectionHeader>Assessment & Plan</SectionHeader>
      <CompletedMentalStatusExamination results={Object.entries(details)} />
      <CompletedConsultationFieldLayout
        title="Observations and diagnostic considerations"
        description={diagnostic_considerations}
      />
      <CompletedConsultationFieldLayout
        title="Treatment plan"
        description={treatment_plan}
      />
      <CompletedConsultationFieldLayout
        title="Admission decision"
        description={admissionDecisionText}
      />
      <CompletedConsultationFieldLayout
        title="Refer patient"
        description={referralDecision}
        extraData={refer_note}
      />
    </div>
  );
};
