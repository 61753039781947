import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { reOpenConsultation } from "~/providers/api";

interface UseReOpenConsultationProps {
  patientId: string;
  onSuccess: () => void;
}
export const useReOpenConsultation = ({
  patientId,
  onSuccess,
}: UseReOpenConsultationProps) => {
  const queryClient = useQueryClient();
  const { mutate: reOpenConsultationMutation, isPending } = useMutation({
    mutationFn: reOpenConsultation,
    onError: () =>
      toast.error(
        "There was an issue reopening the consultation. Please try again later.",
      ),
    onSuccess: async () => {
      toast.success("Consultation re-opened successfully!");
      await queryClient.invalidateQueries({
        queryKey: ["getPatient", patientId],
      });
      onSuccess();
    },
  });

  return { reOpenConsultationMutation, isPending };
};
