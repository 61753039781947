import { DeepPartial } from "react-hook-form";

import {
  PatientConsultationReport,
  PatientConsultationReportData,
} from "../utils/types/consultation";
import { api } from "./axios";

const getConsultationReportUrl = (consultationId?: string) =>
  `/v1/team-members/consultations/${consultationId}/reports`;

export const getConsultationReport = async (consultationId?: string) => {
  const { data } = await api.get<PatientConsultationReport | null>(
    getConsultationReportUrl(consultationId),
  );

  return data;
};

export const updateConsultationReport = async (
  consultationReport: DeepPartial<PatientConsultationReportData>,
  consultationId?: string,
) => {
  const { data } = await api.put<PatientConsultationReportData>(
    getConsultationReportUrl(consultationId),
    consultationReport,
  );
  return data;
};

export const completeConsultationReport = async (
  consultationReport: PatientConsultationReportData,
  consultationId?: string,
) => {
  const { data } = await api.post<PatientConsultationReportData>(
    `${getConsultationReportUrl(consultationId)}/complete`,
    consultationReport,
  );
  return data;
};
